<div [class.show-wrapper]="showWrapper"  class="notification d-flex align-center c-pointer pr-26">
    <div class="icon flex-center"></div>
  
    <div class="text w-100 pl-12">
      <div class="line-1 w-100"></div>
      <div class="line-2 mt-6"></div>
    </div>
  </div>
  
  <div [class.show-wrapper]="showWrapper"  class="notification d-flex align-center c-pointer pr-26 mt-20">
    <div class="icon flex-center"></div>
  
    <div class="text w-100 pl-12">
      <div class="line-1 w-100"></div>
      <div class="line-2 mt-6"></div>
    </div>
  </div>
  
  <div [class.show-wrapper]="showWrapper"  class="notification d-flex align-center c-pointer pr-26 mt-20">
    <div class="icon flex-center"></div>
  
    <div class="text w-100 pl-12">
      <div class="line-1 w-100"></div>
      <div class="line-2 mt-6"></div>
    </div>
  </div>
  