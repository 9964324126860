<div class="content flex-column">
  <h2 class="title mb-32 neo-arial">{{ 'onboarding.sidebar.personalInfoLabel' | translate }}</h2>

  <div class="control-wrapper">
    <label class="neo-arial flex-column">
      <p>{{ 'userProfile.aboutLabel' | translate }} <small class="neo-arial-regular">(Optional)</small></p>
      <textarea cols="30" rows="10" placeholder="Personal info/bio" class="mt-12" formControlName="about"></textarea>
    </label>

    <label class="neo-arial flex-column mt-24 pb-12">
      <p>{{ 'companyManagement.form.linkedinUrlLabel' | translate }} <small class="neo-arial-regular">(Optional)</small></p>

      <neo-text-input
        [submitted]="formSubmitted"
				placeholder='www.linkedin.com/in/johndoe'
				formControlName="linkedInUrl"
				class='mt-12'
        [labelName]="'companyManagement.form.linkedinUrlLabel' | translate"
        [showLabel]="false"></neo-text-input>
    </label>
  </div>
</div>

<neo-wizard-nav-controls (goNext)="goForward(stepsList.GeographicalPurview)" (goBack)="changeStep(stepsList.Location)">
</neo-wizard-nav-controls>
