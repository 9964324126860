import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OnboardingStepsEnum } from '../../enums/onboarding-steps.enum';
import { OnboardingWizardService } from '../../services/onboarding-wizard.service';

@Component({
  selector: 'neo-onboarding-personal-info',
  templateUrl: 'onboarding-personal-info.component.html',
  styleUrls: ['../../onboarding-wizard.component.scss', 'onboarding-personal-info.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnboardingPersonalInfoComponent {
  stepsList = OnboardingStepsEnum;
  formSubmitted: boolean;

  constructor(public controlContainer: ControlContainer, public onboardingWizardService: OnboardingWizardService) {}

  goForward(step: OnboardingStepsEnum): void {
    this.formSubmitted = true;
    if (this.controlContainer.control.get('linkedInUrl').invalid) return;

    this.changeStep(step);
  }

  changeStep(step: OnboardingStepsEnum): void {
    this.onboardingWizardService.changeStep(step);
  }
}
