import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { BreadcrumbInterface } from '../../interfaces/breadcrumb.interface';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { Router } from '@angular/router';
import { PermissionTypeEnum } from '../../enums/permission-type.enum';
import { AuthService } from '../../services/auth.service';
import { PermissionService } from '../../services/permission.service';
import { UserInterface } from '../../../shared/interfaces/user/user.interface';

@Component({
  selector: 'neo-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs$: Observable<BreadcrumbInterface[]>;
  currentUser: UserInterface;

  private readonly excludedRoutes: string[] = ['topics'];

  constructor(
    private readonly breadcrumbService: BreadcrumbService,
    private router: Router,
    private readonly authService: AuthService,
    private readonly permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.breadcrumbs$ = this.breadcrumbService.getBreadcrumbs();
    this.authService.currentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
    });
  }

  navigationHandling(route: string) {
    if (!this.isClickable(route) || this.excludedRoutes.includes(route)) {
      return;
    }
    this.router.navigate([route]);
  }

  isClickable(route: string): boolean {
    return !(
      route === 'projects' &&
      !this.permissionService.userHasPermission(this.currentUser, PermissionTypeEnum.ProjectCatalogView) &&
      !this.permissionService.userHasPermission(this.currentUser, PermissionTypeEnum.ProjectsManageAll)
    );
  }
}
