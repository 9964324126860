export enum InitiativeApiEnum {
  GetInitiativeScaleTypes = 'initiative/get-region-scale-types',
  CreateUpdateInitiative = 'initiative/create-or-update',
  GetRecommendedArticles = 'initiative/get-recommended-articles',
  SaveInitiativeContentItem = 'initiative/save-content-item',
  GetInitiativeAndProgressDetailsById = 'initiative/get-initiative-and-progress-details',
  Delete = 'initiative/delete',
  UpdateInitiativeSubStep = 'initiative/update-initiative-substep',
  GetSavedInitiativeArticle = 'initiative/get-saved-articles',
  GetAllInitiatives = 'initiative/get-all-initiatives',
  RemoveSavedContent = 'initiative/remove-saved-content',
  GetInitiativesAndProgressDetailsByUserId = 'initiative/get-user-all-initiatives',
  GetNewRecommendationsCount = 'initiative/get-new-recommendations-count',
  GetRecommendedTools = 'initiative/get-recommended-tools',
  GetRecommendedConversations = 'initiative/get-recommended-conversations',
  GetRecommendedCommunityUsers = 'initiative/get-recommended-community-users',
  GetSavedInitiativeConversations = 'initiative/get-saved-conversations',
  GetInitiativeAttachedDetails = 'initiative/get-initiatives-with-attached-content-details',
  AttachContentToInitiative = 'initiative/attach-to-content',
  SaveInitiativeFile = 'initiative/upload-file',
  GetInitiativeFiles = 'initiative/get-saved-files',
  GetSavedInitiativeTools = 'initiative/get-saved-tools',
  GetRecommendedProjects = 'initiative/get-recommended-projects',
  CheckFileExist = 'initiative/is-file-exist',
  GetSavedInitiativeProjects = 'initiative/get-saved-projects'
}
