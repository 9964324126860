<neo-modal
  (closed)="closed.emit()"
  [titleSeparator]="true"
  [title]="'landing.footer.privacyPolicyLabel' | translate"
  size="large"
>
  <h5>1. Changes to this Privacy Policy</h5>
  <br />
  <br />
  <strong
    >Schneider Electric may modify this Privacy Policy as needed. This new Privacy Policy has evolved since its last
    update of 25 May 2018.</strong
  >
  <br />
  <br />
  Schneider Electric reserves the right to modify this Privacy Policy as needed, for example to comply with evolutions
  in laws, regulations, Schneider Electric practices and procedures, or requests by supervisory authorities.
  <br />
  <br />
  In this case, Schneider Electric will inform impacted individuals of material changes in this Privacy Policy.
  Schneider Electric will post new versions on relevant internal and external websites and digital platforms.
  <br />
  <br />
  The main changes and reasons for changes between the current version of this Privacy Policy and the previous version
  of 25 May 2018 are the following:
  <br />
  - Changes to address new regulatory requirements resulting from the CCPA (California Consumer Privacy Act);
  <br />
  - Specifying in our Cookie Notice examples of cookies and similar technologies we use.
  <br />
  <br />
  <h5>2. Why this Privacy Policy?</h5>
  <br />
  <br />
  <strong
    >Schneider Electric is committed to protecting your privacy and personal information. This Privacy Policy informs
    you of our privacy practices and of how personal information is protected. You should read it before accessing
    Schneider Electric digital content.</strong
  >
  <br />
  <br />
  Schneider Electric strongly supports the fundamental rights to privacy and data protection as well as compliance with
  national and international privacy laws.
  <br />
  <br />
  This Privacy Policy describes how Schneider Electric processes and protects personal information of individuals who
  use our websites and other digital platforms as well as in the context of our offline business activities.
  <br />
  <br />
  Schneider Electric has adopted an internal Global Data Privacy Policy which is applicable to all our subsidiaries for
  the collection, processing, use, dissemination, transfer and storage of personal information. It imposes common rules
  for all of our subsidiaries of all countries, and aims at ensuring a high level of protection of personal information
  within Schneider Electric.
  <br />
  <br />
  Schneider Electric has also established this Privacy Policy as an external publication of our Binding Corporate Rules
  (“BCR”), approved by data protection supervisory authorities of the European Union in November 2012 for the processing
  of personal information from the EEA as a data controller. In the Schneider Electric’s Binding Corporate Rules, we
  respect and take into account the major principles of EU data protection rules as one of our Head Offices is located
  in the European Union.
  <br />
  <br />
  <h5>3. What is the scope of this Privacy Policy? Who is in charge of data processing?</h5>
  <br />
  <br />
  <strong
    >This Privacy Policy applies to all Schneider Electric subsidiaries and digital content, unless a specific privacy
    notice has been released to supplement it or to replace it. You should check the privacy notices made available to
    you on each digital platform such as websites or applications.</strong
  >
  <br />
  <br />
  Schneider Electric is a global company, with legal entities, business processes, management organizations, and system
  infrastructure that cross borders. This Privacy Policy applies to all Schneider Electric data processing activities
  run by our controlled subsidiaries, including product and service offerings and digital content (e.g. event
  registrations, websites, applications, tutorials, e-trainings, newsletters, advertising, and other communication). It
  may however be supplemented by a more specific privacy notice/statement/policy or even superseded by another policy,
  specific to a particular Schneider Electric program, product, service, content or entity. It is important that you
  read the statements made available to you for your full information. For instance, the processing of job candidate
  applications is governed by the privacy notice posted on our Career Portal.
  <br />
  <br />
  The data controllers of the data processing activities are the Schneider Electric subsidiaries who have determined the
  data processing means and purposes. They may vary on a case-by-case basis. In many instances, Schneider Electric
  Industries SAS, 35 rue Joseph Monier 92500 Rueil-Malmaison - France, Schneider Electric’s Head Office, is the data
  controller of global data processing activities. For information you can contact us at
  <a href="mailto:Global-Data-Privacy@schneider-electric.com" title="Send an email"
    >Global-Data-PrivacyATschneider-electric.com</a
  >
  [Replace in the address AT by @]
  <br />
  <br />
  This Privacy Policy binds all Schneider Electric controlled subsidiaries, and their employees.
  <br />
  <br />
  Any order made by you online is also subject to the terms and conditions provided on the relevant sites. You must read
  them.
  <br />
  <br />
  <h5>4. Why do we collect and use personal information?</h5>
  <br />
  <br />
  <strong
    >We use personal information for various purposes, including to fulfill orders and requests, to manage customer and
    prospect relationships, conduct surveys, improve our products, services, digital content as well as user journeys,
    manage user accounts and programs, analyze activities on our digital platforms, run marketing activities, provide
    user with contextual and relevant information, ensure the security of our activities, protect against fraud, and,
    more generally, run our business activities.</strong
  >
  <br />
  <br />
  Our primary goal in collecting information is to provide you with superior service and a smooth, efficient and
  personalized experience while using our digital content.
  <br />
  <br />
  Schneider Electric collects personal information for the following purposes:
  <br />
  - To fulfill orders of product, services and digital content. We collect this information to deliver your order, to
  obtain payment, to provide the functionalities of our applications, to communicate with you about the status of your
  order and for contract management purposes;
  <br />
  - To better address future requests for information and support. We keep record of exchanges including personal
  information to provide an optimized service on contacting us for support;
  <br />
  - For relationship management purposes which includes communicating about the products, services and digital content
  including email communications and messaging;
  <br />
  - To conduct surveys and polls. We may collect personal information to complete surveys and contact you for follow-up.
  We use this information to measure satisfaction, get to know our customers better and improve products, services and
  digital content;
  <br />
  - To improve our products, services and digital content; for instance, we analyze the use of our applications and
  websites in order to identify areas and functionalities where users have difficulties, and we improve them;
  <br />
  - To learn about your browsing and searching activity. We collect navigation information when you visit our digital
  content or click on links for products and services. We collect this information to improve user experience for next
  visits (e.g., country selection or profile-focused content), help our websites run more efficiently, to gather broad
  demographic information to analyze the websites’ activity and performance, and to evaluate the relevance and
  effectiveness of our advertising to better serve our customers. We may use cookies and other identifiers or similar
  technologies (referred to hereinafter as "Cookies") to obtain information on the referring website, type of browser
  used, the content viewed, as well as the date and time of access to the digital content. You may read our Cookie
  Notice for more information on our use of Cookies and your options for managing Cookies;
  <br />
  - To manage user digital accounts. We collect personal information at registration on our digital platforms (e.g.
  partner portal, ordering platform, mobile apps) to authenticate you, manage credentials to enable you to navigate
  through several digital platforms without having to re-authenticate (cf. single sign-on) and to interact with you;
  <br />
  - To run promotional programs and activities: we collect personal information when you join a promotional or reward
  program or activity. We use this information to administer the program or activity, to send relevant e-mails about the
  program and activity, notify winners, and make the winners' list publicly available in accordance with applicable
  regulations and laws;
  <br />
  - To send you marketing information by mail, fax, phone, text messages, email and electronic communications about
  promotions, news and new products or services that we think may be of interest to you in compliance with applicable
  opt-in and opt-out requirements. This can be conducted by Schneider Electric, its subsidiaries or selected
  third-parties acting on our behalf;
  <br />
  - To provide you with certain communications and/or targeted information about our products and services (or of our
  subsidiaries) through pop-ups, banners, videos, emails and any other advertising format. For instance, we may provide
  you with contextual advertising or other content, based on browsing information. We may also use location data to
  inform you about local promotions and activities. Please read our Cookie Notice for more information on the use of
  Cookies and your options for managing them;
  <br />
  - To combine information obtained online from different digital platforms and the information we collected about you
  offline to create a profile of you that we may use for giving you personalized services. For example, a user that we
  believe is an electrician (due notably to information provided at the time of registration and other information
  obtained from third-party sources), may see profile related advertising and/or on third-party websites and apps that a
  user with another energy-related specialty would not see. Similarly, users may see different content or receive
  different emails containing different advertisements or surveys. We may also use Cookies to geographically target
  users.
  <br />
  - To ensure the security of our products, services and digital content, of our activities and of others, for the
  protection against fraud and the compliance with our terms and conditions and this Privacy Policy. We may also
  investigate potential breaches;
  <br />
  - To enable or administer our business, such as for quality control and consolidated reporting;
  <br />
  - To support corporate transactions or reorganizations in which Schneider Electric is involved;
  <br />
  - For business continuity and disaster recovery;
  <br />
  - To comply with legal obligations to which we are subject. For example, accounting and tax obligations;
  <br />
  - Any other purpose otherwise conveyed to you.
  <br />
  <br />
  <h5>5. What type of personal information do we process?</h5>
  <br />
  <br />
  <strong
    >Schneider Electric processes various types of personal information including identity and contact related
    information, professional related information, information about preferences, interactions with us, financial
    related information, online traffic data and the content you provide to us. In most instances this information is
    obtained from customers, partners and users. We also purchase lists from marketing agencies and obtain information
    from our partners, through Cookies and social networks.</strong
  >
  <br />
  <br />
  Personal information (also called personal data) is any information relating to an identified or identifiable
  individual. An identifiable individual is one who can be identified, directly or indirectly, in particular by
  reference to an identifier or to one or more factors specific to his physical, physiological, mental, economic,
  cultural or social identity.
  <br />
  <br />
  The types of personal information we process about you may include:
  <br />
  - Identity and contact related information, such as name, company, email address, phone number, photo, age range,
  contact addresses;
  <br />
  - Professional information, such as customer type, purchasing authority, purchasing timeframe; acquisition and use of
  Schneider Electric products, services and digital content;
  <br />
  - Information (e.g. energy consumption) which is specific to the digital platform you use
  <br />
  - Your preferences such as product and service preferences, contact preferences, marketing preferences;
  <br />
  - Your interactions with us such as queries, orders, claims, survey answers;
  <br />
  - Financial-related information such as bank account details, credit card information;
  <br />
  - Online traffic data such as IP address, device and system identifiers, your Schneider Electric user ID and password,
  referring website, type of browser used, consulted content, and location, based either on your IP address or on
  information transmitted by your mobile device;
  <br />
  - Content that you have provided via online forums or otherwise (see section 6 “Your content” below).
  <br />
  <br />
  This Privacy Policy does not cover personal information rendered anonymous, that is if individuals are no longer
  identifiable or are identifiable only with a disproportionately large expense in time, cost, or labor. If anonymized
  data becomes identifiable, then this Privacy Policy shall apply.
  <br />
  <br />
  In most instances we collect personal information directly from users who have a business relationship with us. We may
  also obtain information through a reseller or a business partner, by purchasing customer lists from marketing
  agencies, from your online browsing experience, from social networks when you connect with these network’s credentials
  and through Cookies. You may obtain more precise information on the third-party source (if any) of personal
  information by contacting us at the email address indicated in section 10.
  <br />
  <br />
  <h5>6. Your content</h5>
  <br />
  <br />
  <strong
    >You may choose to contribute content, including photos or comments, to online forums, applications or other digital
    platforms operated by Schneider Electric. Your content must be harmless. It must respect the law, the rights and
    interests of others. You need to have obtained consent before sharing someone else’s data. You should apply caution
    before sharing information in a forum.</strong
  >
  <br />
  <br />
  The content provided to us must respect the rights and interests of others, including their rights to protection of
  personal information and privacy. It should not be offensive, disrespectful or be harmful in any way.
  <br />
  <br />
  Any provision of personal information to Schneider Electric about another individual must be compliant with privacy
  laws, including with notice and consent requirements for the disclosure of that information.
  <br />
  <br />
  While Schneider Electric strives to protect your personal information, providing it online on shared forums is not
  risk-free. If you post, comment, indicate interest, or share personal information, including photographs, to any
  forum, social network or blog, please be aware that any personal information you submit can be read, viewed,
  collected, or used by other users of these forums, and could be used to contact you, send you unsolicited messages, or
  for purposes that neither you nor Schneider Electric have control over. Schneider Electric is not responsible for the
  personal information you choose to submit in these forums. You should apply caution before deciding to share
  information about yourself or another person.
  <br />
  <br />
  <h5>7. Who do we share personal information with?</h5>
  <br />
  <br />
  <strong
    >We share personal information with the Schneider Electric subsidiaries and the service providers involved in our
    activities, with advertising and marketing agencies, with social networks if you use their login credentials, with
    third-party websites if you register with Schneider Electric credentials, competent regulatory bodies and
    authorities and business successors.</strong
  >
  <br />
  <br />
  Schneider Electric is a global group of companies which works as one. To provide the best service to customers,
  prospects and users, personal data may be shared amongst personnel working for different entities. It may be the case,
  for example, for customer relationship management, sales or product support, marketing, product development purposes,
  improvement of the products, services and digital content, data quality checks, or security, finance, regulatory and
  compliance purposes.
  <br />
  <br />
  We resort to service providers to carry out data processing activities and to provide our products, services and
  online content to you. These service providers include, without limitation, providers of hosting facilities,
  information systems, advertising and marketing agencies, IT support, security services, financial services, carriers
  who deliver products, outside accounting firms, lawyers and auditors.
  <br />
  <br />
  We ensure all service providers working under contract for Schneider Electric are compliant with data privacy laws and
  aligned with Schneider Electric guidelines.
  <br />
  <br />
  We also share the information as follows:
  <br />
  - In connection with the provision of advertising, we may share some limited personal information (e.g. device
  identifiers, Cookie identifiers) with ad exchanges or agencies that manage advertising on third-party websites and
  apps on which you may see advertising.
  <br />
  - You may access our digital content and/or register through login credentials of third-party websites (such as
  Facebook and LinkedIn). If you do so, we will be able to access some of the information you registered on these social
  networks, to assist you to pre-fill the registration form for Schneider Electric digital content. By using the login
  credentials of these third-party websites, you may at the same time inform your contacts on these sites that you have
  created an account with Schneider Electric. If you want to know more about the information accessible by your contacts
  on these sites and how to restrict their communication, we encourage you to read their terms of use and privacy
  policies.
  <br />
  - Schneider Electric may disclose your personal information as necessary to potential buyers and successors in title,
  to facilitate a merger, consolidation, transfer of control or other corporate reorganization in which Schneider
  Electric participates.
  <br />
  - Where required by law or court orders or to protect our legal rights, we will disclose your personal information to
  government agencies, regulators and competent authorities.
  <br />
  - In other ways described in this Privacy Policy or to which you have otherwise consented.
  <br />
  - Aggregated with other information, in such a way that your identity cannot reasonably be determined (for example,
  statistical compilations).
  <br />
  <br />
  We will not sell or rent your personal information to a third party without your permission.
  <br />
  <br />
  <h5>8. How do we protect personal information?</h5>
  <br />
  <br />
  <strong
    >Schneider Electric complies with widely recognized key data protection principles (fairness, purpose limitation,
    data quality, data retention, compliance with individuals’ rights, security) and takes reasonable measures for the
    security of personal information.</strong
  >
  <br />
  <br />
  Schneider Electric respects the privacy rights and interests of individuals. Schneider Electric and its subsidiaries
  observe the following principles when processing personal information:
  <br />
  <br />
  1. Processing personal information fairly and lawfully;
  <br />
  2. Collecting personal information for specified, legitimate purposes and not processing it further in ways
  incompatible with those purposes;
  <br />
  3. Collecting personal information which is relevant to and not excessive for the purposes for which it is collected
  and used. We may render information anonymous when feasible and appropriate, depending on the nature of the data and
  the risks associated with the intended uses;
  <br />
  4. Maintaining accurate personal information, and where necessary, kept up-to-date. We will take reasonable steps to
  rectify or delete information that is inaccurate or incomplete;
  <br />
  5. Keeping personal information only as long as it is necessary for the purposes for which it was collected and
  processed;
  <br />
  6. Processing personal information in accordance with individuals’ legal rights;
  <br />
  7. Taking appropriate technical, physical, and organizational measures to prevent unauthorized access, unlawful
  processing, and unauthorized or accidental loss, destruction, or damage to personal information;
  <br />
  8. When processing sensitive personal information, ensuring appropriate notice and consent or that the processing
  otherwise complies with applicable law;
  <br />
  <br />
  Schneider Electric and all its subsidiaries must ensure that the above principles are complied with.
  <br />
  <br />
  Schneider Electric and its subsidiaries are committed to taking commercially reasonable technical, physical, and
  organizational measures to protect personal information against unauthorized access, unlawful processing, accidental
  loss or damage, and unauthorized destruction. We offer the use of secure servers to enable you to place orders or to
  access your account information. We implement access control measures for our internal systems that hold personal
  information. Authorized users are given access to such systems through the use of a unique identifier and password.
  Access to personal information is provided to our staff for the sole purpose of performing their job duties. We
  sensitize our employees on proper use and handling of personal information. We also require our service providers to
  maintain compliant security measures. We implement security measures to determine the identity of registered users, so
  that appropriate rights and restrictions can be enforced for these users. In case of a registered user, we use both
  log ins and passwords for authentication. You are responsible for maintaining the security of your log-in credentials.
  <br />
  <br />
  By using our digital content or providing personal information to us, you agree that we may communicate with you
  electronically or otherwise about related security, privacy, use and administrative activities. In spite of our
  efforts to implement appropriate security measures, online browsing carries inherent risks and we cannot guarantee
  that it is risk-free.
  <br />
  <br />
  <h5>9. Third-party websites and social media</h5>
  <br />
  <br />
  <strong
    >You should read the privacy policy of third-party websites and social media and adjust privacy settings as you see
    fit. Non-Schneider Electric websites and platforms are not covered by this Privacy Policy.</strong
  >
  <br />
  <br />
  Schneider Electric digital platforms may provide links to third-party applications, products, services, or websites
  for the user convenience and information. If you access those links, you will leave the Schneider Electric digital
  platform. Schneider Electric does not control those third-party sites or their privacy practices, which may differ
  from Schneider Electric’s practices. We do not endorse or make any representations about third-party sites. The
  personal information you choose to provide or that is collected by, these third parties are not covered by the
  Schneider Electric Privacy Policy. We encourage you to review the privacy policy of any site you interact with, before
  allowing the collection and use of your personal information.
  <br />
  <br />
  We also provide social media links that enable you to share information with your social networks and to interact with
  Schneider Electric on various social media sites. The use of these links may result in the collection or sharing of
  information about you. We encourage you to review the privacy policies and the privacy settings of the social media
  sites with which you interact to make sure you understand the information that may be collected, used, and shared by
  those sites and to adjust these settings as you see fit.
  <br />
  <br />
  <h5>10. What are your rights?</h5>
  <br />
  <br />
  <strong
    >Schneider Electric will comply with your data protection rights, including your rights to request access to your
    personal information and to request that it be deleted or amended. You can always opt out of any direct marketing
    activity performed by Schneider Electric.</strong
  >
  <br />
  <br />
  Schneider Electric will comply with your data protection rights, including your rights to access and correct your
  personal information. You may inquire about the nature of the personal information stored or processed about you by
  any Schneider Electric entity. You will be provided access to your personal information, regardless of the location of
  the data processing and storage.
  <br />
  <br />
  You can request access to your personal information and request that it be deleted or amended at any time. Individual
  Rights Requests can be sent to:
  <a href="mailto:Global-Data-Privacy@schneider-electric.com" title="Send an email"
    >Global-Data-PrivacyATschneider-electric.com</a
  >
  [Replace in the address AT by @].
  <br />
  <br />
  You always have the right to opt out of our marketing communications. Your prior consent is sought, when required by
  any applicable law. To opt out of marketing emails, simply use the functionality provided at the bottom of any email
  we send.
  <br />
  <br />
  Country-specific sections may supplement this section.
  <br />
  <br />
  <strong>11. Important information for individuals in the European Economic Area</strong>
  <br />
  <br />
  <strong
    >Schneider Electric also complies with GDPR- specific requirements, including those relating to legal grounds for
    processing, cross-border data transfers, automated decision making and profiling, data retention, additional rights,
    claims and DPO contact details.</strong
  >
  <br />
  <br />
  <strong>11.1 On what legal ground is Schneider Electric relying to use personal information?</strong>
  <br />
  <br />
  The use of personal information is necessary, with respect to the purposes mentioned in section 3 above, to,
  respectively:
  <br />
  <br />
  - Perform the contract entered into between Schneider Electric and you in the context of the use of our products,
  services or digital content. The performance of the contract includes knowing who you are, and your specialty and
  interacting with you for its performance. It includes as well addressing your requests for information, support, your
  job applications, managing your accounts and your enrollment into our programs and ensuring compliance with terms and
  conditions and with this Privacy Statement.
  <br />
  - Comply with legal obligations to which we are subject. For instance, mandatory tax and accounting obligations or
  addressing filing obligations and requests by competent regulatory bodies and authorities.
  <br />
  - When it comes to:
  <br />
  o Surveys, some are carried out in our legitimate interest because they are to measure your satisfaction with services
  you requested from us. For other surveys we will request your consent. You will always be free not to respond.
  <br />
  o Providing you with marketing communications and interest-based advertising, these activities carried out by us and
  by our subsidiaries are in our legitimate interests. We will always provide you a possibility to opt out and where
  required by applicable law we will seek your prior consent.
  <br />
  o Using location data transmitted by your mobile device, we will seek your prior consent, where required by applicable
  law.
  <br />
  <br />
  We may also process your personal information for the purposes of our legitimate interests provided that such
  processing shall not outweigh your rights and freedoms, in particular to:
  <br />
  - Protect you, us or others from threats (such as security threats or fraud),
  <br />
  - Comply with the laws that are applicable to us around the world,
  <br />
  - Improve our products, services and digital content,
  <br />
  - Perform profiling to provide targeted content and interest-based advertising
  <br />
  - Share information between Schneider Electric subsidiaries and service providers as described in section 7,
  <br />
  - Understand and improve our online activities, our business, our customer basis and relationships generally.
  <br />
  - Enable or administer our business, such as for quality control and consolidated reporting
  <br />
  - Manage corporate transactions, such as mergers or acquisitions.
  <br />
  <br />
  <h5>11.2 Will personal information be transferred abroad?</h5>
  <br />
  <br />
  At Schneider Electric, a global company, the teams working on fulfilling the data processing purposes may have global
  or multi-country roles. They can then be located anywhere in the world where
  <a
    href="https://www.se.com/ww/en/locator/?locale=WW_en30&amp;type=map&amp;poco=all&amp;locator=offices&amp;header=se&amp;mxdi=500"
    target="_blank"
    title="Discover our location"
    >Schneider Electric operates</a
  >, including outside the European Union, in countries which do not have equivalent standards for the protection of
  personal information as the country where you are located. We may also transfer data to service providers located
  outside of the EU, including in the United States of America. In the event that these data transfers cannot claim an
  adequacy decision by the European Commission, Schneider Electric will ensure that they comply with applicable legal
  requirements, for example by executing standard contractual clauses or through its Binding Corporate Rules for
  intragroup transfers as a data controller. To obtain more details on these transfers and, where appropriate, copies of
  the applicable safeguards implemented, you may contact:
  <a href="mailto:Global-Data-Privacy@schneider-electric.com" title="Send an email"
    >Global-Data-PrivacyATschneider-electric.com</a
  >
  [Replace in the address AT by @]
  <br />
  <br />
  <strong>11.3 Do we use profiling and make automated decisions about individuals?</strong>
  <br />
  <br />
  We may use profiling to provide you with online content which we believe corresponds to your interests. We combine
  registration information, such as work specialty and information about online activity to know you better, and provide
  you with online content corresponding to your profile.
  <br />
  <br />
  We will not make automated-decisions about you that may significantly affect you, unless:
  <br />
  - the decision is necessary as part of a contract;
  <br />
  - we have your explicit consent;
  <br />
  - or we are required by law to use the technology.
  <br />
  In these cases, due notice will be provided.
  <br />
  <br />
  <h5>11.4 How long will personal information be retained?</h5>
  <br />
  <br />
  We will retain personal information for as long as the information is needed for the purposes set forth in this
  Privacy Policy and for any additional period that may be required or permitted by law. More precise information is
  provided in privacy notices applicable to specific digital content. In general data retention periods are determined
  taking into consideration:
  <br />
  - The duration of our relationship (e.g. contract performance duration, account de-activation, your legitimate need to
  be recognized when contacted by us)
  <br />
  - Legal requirements for keeping data
  <br />
  - Statute of limitations
  <br />
  <br />
  For marketing purposes, we keep relevant customer data for three years after the end of our relationship or since the
  last interaction with us.
  <br />
  <br />
  <h5>11.5 What are your additional rights?</h5>
  <br />
  <br />
  In addition to the rights provided in section 10 above, you may ask us to erase, restrict or port your personal
  information and object to the use of your personal information. When data processing is based on individual consent,
  you have the right to withdraw your consent at any time by sending a request to the following email address:
  Global-Data-PrivacyATschneider-electric.com [Replace in the address AT by @]
  <br />
  For processing necessary to perform the contract, or based on legitimate interest, we may not be able to accommodate
  the request to stop the processing, or if we do so, it may mean that you can no longer access the services or the
  digital content.
  <br />
  <br />
  <h5>11.6 Where to ask questions and file a claim?</h5>
  <br />
  <br />
  Questions or comments about the Schneider electric privacy practices or this Privacy Policy can be addressed to the
  Group Data Protection Officer (Group DPO):
  <br />
  <a href="mailto:DPO@schneider-electric.com" title="Send an email">DPOATschneider-electric.com</a>
  [Replace in the address AT by @]
  <br />
  DPO, 35 rue Joseph Monier CS30323, 92506 Rueil-Malmaison - France
  <br />
  If you believe that Schneider Electric has processed your personal information in violation of applicable law or of
  this Privacy Policy, you may file a complaint with the Group DPO. You may also contact a supervisory authority. The
  supervisory authority for Schneider Electric is the CNIL in France; you may contact the supervisory authority in your
  country.
  <br />
  <br />
  <h5>12. Important information for California residents</h5>
  <br />
  <br />
  Schneider Electric in the USA is providing this supplemental privacy notice to give California residents the
  additional information required by the California Consumer Privacy Act (the “CCPA”).
  <br />
  <br />
  At Schneider Electric, we are committed to protecting the privacy and security of all the personal information that is
  entrusted to us. We have a Global Privacy Program, based on Binding Corporate Rules, to demonstrate our strong support
  for the fundamental rights to privacy and data protection. We are also committed to complying with the privacy laws in
  all the countries where we operate. The Schneider Electric Privacy Policy describes our privacy and security
  practices.
  <br />
  <br />
  Schneider Electric is primarily focused on serving business and professional customers. We do collect a limited amount
  of consumer data from individuals who provide it to us via our online forms or by otherwise interacting with our
  websites or apps. We also receive consumer data in connection with our smart home products. This supplemental privacy
  notice explains how we comply with the CCPA for this information.
  <br />
  <br />
  <h5>12.1 CCPA Rights</h5>
  <br />
  <br />
  The CCPA provides California residents with specific privacy rights, including the right to receive a privacy notice,
  the right to know what information we have collected about you during the past 12 months, and the right to know what
  categories of personal information we have shared with third parties. CCPA gives California residents the right to
  opt-out of having their personal information sold1. CCPA also gives California residents the right to request deletion
  of their personal information.
  <br />
  <br />
  For information that Schneider Electric collects subject to the CCPA, this notice describes the categories of
  information that we collect from California residents generally, the purposes for which we use the information, and
  the categories of third parties to whom we disclose the information for business purposes. We may collect and share
  other categories of information with your consent.
  <br />
  <br />
  Schneider Electric does not sell personal information. As described below, we may share personal information with our
  Affiliates2 and service providers. We may also disclose personal information to third parties for business purposes as
  permitted by CCPA, such as to our auditors, for compliance or security, or in connection with mergers and
  acquisitions.
  <br />
  <br />
  We may also share personal information with third parties based on your consent, such as if you request a referral to
  a distributor or installer, enroll in a co-branded marketing program or if you accept our use of third-party
  advertising cookies, as described in our Cookie Notice.
  <br />
  <br />
  <h5>12.2 How to Exercise Your Rights</h5>
  <br />
  <br />
  If you are a California resident, you may exercise your rights or authorize another person to act on your behalf by:
  <br />
  • Calling Schneider Electric at 800-789-3508
  <br />
  • Emailing us: Global-Data-Privacy@schneider-electric.com
  <br />
  <br />
  Please note that we will need to verify your identity before we can fulfill your request. Because the information that
  we maintain subject to CCPA generally consists of marketing information, we will generally verify your identity using
  your email address. We will respond to requests using the email address that is associated with the information we
  maintain.
  <br />
  <br />
  If you would like to designate an agent, please send an email from your own email address to
  Global-Data-Privacy@schneider-electric.com indicating the name and email address of your agent. We will respond to
  that person’s requests using both your email address and the agent’s email address.
  <br />
  <br />
  Please understand that your rights are subject to some limitations. If you request that we delete your personal
  information, we will do so except in those situations where our retention is required for our internal business
  purposes, to finalize the ongoing business operations we have we you, to log your request or otherwise permitted by
  CCPA (such as for fraud prevention or legal compliance). In these situations, we will retain your information in
  accordance with our records retention program and securely delete it at the end of the retention period.
  <br />
  <br />
  Additionally, please note that many companies sell and install Schneider Electric products. We do not operate these
  companies, and we do not have any access to their databases. If you have registered with or purchased Schneider
  Electric products from another company, please contact that company directly to exercise your CCPA rights.
  <br />
  <br />
  <h5>12.3 Financial Incentives</h5>
  <br />
  <br />
  Schneider Electric collects personal information in order to deliver offers and promotions and to enable loyalty
  programs. While we cannot calculate the precise value of your information to us, our offers and incentives generally
  reflect the value of the relationships that we have with the individuals who participate in the program.
  <br />
  <br />
  We will not discriminate against you if you exercise your rights under CCPA. However, if you ask us to delete your
  information, you will not be able to receive additional offers or promotions. Any offers or promotions sent to you
  previously will continue to be honored according to their original terms.
  <br />
  <br />
  <h5>12.4 CCPA Right to Know Disclosures</h5>
  <br />
  <br />
  <table
    class="table"
    data-asc-label="Sorted in ascending order"
    data-default-label="No sorting applied"
    data-desc-label="Sorted in descending order"
    data-enable-ordering="false"
    data-order-column="0"
    style="min-inline-size: calc(3 * 150px)"
  >
    <thead>
      <tr>
        <th data-order-direction="asc">
          <div class="headings-wrap">
            <strong>Category of Personal Information and Representative Data Elements</strong>
          </div>
        </th>
        <th data-order-direction="asc">
          <div class="headings-wrap">
            <strong>Purpose for Collecting and Sharing the PI</strong>
          </div>
        </th>
        <th data-order-direction="asc">
          <div class="headings-wrap">
            <strong
              >Categories of Third Parties to whom this type of Personal Information is Disclosed for a Business
              Purpose</strong
            >
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Contact Information: Name; Username; Mailing address; Email address; Telephone number; Mobile number</td>

        <td>
          We use this type of information to identify you and communicate with you, including: To send transactional
          messages (such as confirmations); To send marketing communications, surveys, and invitation; To personalize
          our communications and provide customer service, For our Everyday Business Purposes³
        </td>

        <td>
          We may disclose this type of information to our Affiliates and to Service Providers, including to social media
          companies that use the data only to identify which of our customers use their platforms so that we can deliver
          ads to you on the platform. Third parties who deliver our communications, such as the postal service and
          couriers; Third parties who assist us with address hygiene and fulfillment; Other third parties as required by
          law
        </td>
      </tr>
      <tr>
        <td>
          Relationship Information: Personal characteristics and preferences, such as your age range, marital and family
          status; Loyalty and rewards program data; Household demographic data, including census data; Data from social
          media profiles; Hobbies and interests
        </td>

        <td>
          We use this type of information: To better understand you and to understand our customers generally; For
          product and service development and improvement; To identify prospective customers; For internal business
          purposes, such as quality control, training and analytics; For our Everyday Business Purposes
        </td>

        <td>
          We may disclose this type of information to our Affiliates and Service Providers and to: Third parties with
          whom we have joint marketing and similar arrangements; Our lawyers, auditors and consultants; Other third
          parties as required by law
        </td>
      </tr>
      <tr>
        <td>
          Transaction and Interaction Information: Account information and related records; Records related to use of
          our websites and apps; Authentication data (passwords, account security questions); Customer service records;
          Visitor logs
        </td>

        <td>
          We use this type of information: To fulfill our business relationship with you, including customer service;
          For recordkeeping and compliance, including dispute resolution; For internal business purposes, such as
          finance, quality control, training, reporting and analytics; For risk management, fraud prevention and similar
          purposes; For our Everyday Business Purposes
        </td>

        <td>
          We may disclose this type of information to our Affiliates and Service Providers and to: Third parties with
          whom we have joint marketing and similar arrangements; Third parties as needed to complete the transaction,
          including delivery companies, agents and manufacturers; Our lawyers, auditors and consultants Customers, in
          connection with their audits of Schneider Electric Other third parties as required by law
        </td>
      </tr>
      <tr>
        <td>
          Inferred and Derived Information: Propensities, attributes and/or scores generated by internal analytics
          programs
        </td>

        <td>
          We combine inferred data with other relationship information and use this type of information: To better
          understand you and to understand our customers generally; For product and service development and improvement;
          For internal business purposes, such as quality control, training and analytics; For our Everyday Business
          Purposes
        </td>

        <td>
          We may disclose this type of information to our Affiliates and Service Providers and to: Third parties with
          whom we have joint marketing arrangements; Our lawyers, auditors and consultants; Other third parties as
          required by law
        </td>
      </tr>
      <tr>
        <td>
          Online &amp; Technical Information: IP Address; Device identifiers and characteristics; Advertising ID; Web
          Server Logs; First Party Cookies; Third Party Cookies; Web beacons, clear gifs, pixel tags; Server log
          records; Activity log records
        </td>

        <td>
          We use this type of information: For system administration, technology management, including optimizing our
          websites and applications; For information security and cybersecurity purposes, including detecting threats;
          For recordkeeping, including logs and records that maintained as part of Transaction Information; To better
          understand our customers and prospective customers and to enhance our Relationship Information, including by
          associating you with different devices and browsers that they may use; For online targeting and advertising
          purposes; For our Everyday Business Purposes
        </td>

        <td>
          We may disclose this type of information to our Affiliates and Service Providers including to companies such
          as Google that use the data collected by cookies and similar means to help us with our online advertising
          programs, and to: Third parties who assist with our information technology and security programs, including
          companies such as network security services who retain information on malware threats detected; Third parties
          who assist with fraud prevention, detection and mitigation; Third party network advertising partners; Our
          lawyers, auditors and consultants; Other third parties as required by law. We also disclose this information
          with your consent, if you explicitly allow us to place third party advertising cookies. To learn more and
          review your cookie settings, please read our Cookie Notice.
        </td>
      </tr>
      <tr>
        <td>
          IoT and Sensor data: Commands, usage and other data collected, computed or produced by smart home products
          (such as home automation, energy management, safety and security, and electrical products) and their
          associated apps; Diagnostics data (such as context and description of detected errors); Geolocation data
        </td>

        <td>
          We use this type of information: To enable product functionality; For internal business purposes, such product
          development, security, and quality control; For relationship purposes, including analytics regarding product
          usage; For our Everyday Business Purposes
        </td>

        <td>
          We may disclose this type of information to our Affiliates and Service Providers and to: Third parties who
          assist with our information technology and security programs, including network security services and
          cybersecurity consortia • Third parties who assist with fraud prevention, detection and mitigation; Third
          parties as needed to complete the transactions including third parties who provide products and services that
          you connect with ours; Third party network advertising partners; Our lawyers, auditors and consultants; Other
          third parties as required by law
        </td>
      </tr>
      <tr>
        <td>
          Audio Visual Information: We collect this type of information from: Photographs; Video images; CCTV
          recordings; Call center recordings and call monitoring records Voicemails
        </td>

        <td>
          We use this type of information: For internal business purposes, such as call recordings used for training,
          coaching or quality control; For relationship purposes, such as use of photos and videos for social media
          purposes; For premises security purposes and loss prevention; For our Everyday Business Purposes
        </td>

        <td>
          We may disclose this type of information to our Affiliates and Service Providers and to: Third parties who
          assist with our information technology and security programs, and our loss prevention programs; Our lawyers,
          auditors and consultants; Other third parties as required by law
        </td>
      </tr>
      <tr>
        <td>
          Compliance data: We collect this type of information from: Compliance program data, such as records maintained
          to demonstrate compliance with applicable laws; Product safety data and other regulatory information; Records
          related to consumer preferences, such as your opt-ins and opt-outs of marketing programs; Records related to
          CCPA rights requests
        </td>

        <td>
          We use this type of information: To comply with and demonstrate compliance with applicable laws; For legal
          matters, including litigation and regulatory matters, including for use in connection with civil, criminal,
          administrative, or arbitral proceedings, or before regulatory or self-regulatory bodies, including service of
          process, investigations in anticipation of litigation, execution or enforcement of judgments and orders; For
          internal business purposes, such as risk management, audit, internal investigations, reporting, and analytics;
          For our Everyday Business Purposes
        </td>

        <td>
          We may disclose this type of information to our Affiliates and Service Providers and to: Our lawyers, auditors
          and consultants; Regulators, customers and other third parties, in connection with their audits of Schneider
          Electric; Other third parties (including government agencies, courts and opposing law firms, consultants,
          process servers and parties to litigation) in connection with legal matters
        </td>
      </tr>
    </tbody>
  </table>

  <em
    >1. Personal information of children under 16 cannot be sold without affirmative consent. Schneider Electric does
    not sell children’s information.
    <br />
    <br />
    2. Schneider Electric’s Affiliates are companies that are directly or indirectly controlled by Schneider Electric
    SE.
    <br />
    <br />
    3. <strong>Everyday Business Purposes</strong> encompasses the Business Purposes (as defined in the CCPA) and
    following related purposes for which personal information may used:
    <br />
    <br />
    • To provide the information, product or service requested by the individual or as reasonably expected given the
    context in which with the personal information was collected (such as customer credentialing, providing customer
    service, personalization and preference management, providing product updates, bug fixes or recalls, and dispute
    resolution);
    <br />
    • For identity and credential management, including identity verification and authentication, and system and
    technology administration;
    <br />
    • To protect the security and integrity of systems, networks, applications and data, including detecting, analyzing
    and resolving security threats, and collaborating with cybersecurity centers, consortia and law enforcement about
    imminent threats;
    <br />
    • For fraud detection and prevention;
    <br />
    • For legal and regulatory compliance, including all uses and disclosures of personal information that are required
    by law or reasonably needed for compliance with company policies and procedures, such as: anti-money laundering
    programs, security and incident response programs, intellectual property protection programs, and corporate ethics
    and compliance hotlines;
    <br />
    • For corporate audit, analysis and reporting;
    <br />
    • To enforce our contracts and to protect against injury, theft, legal liability, fraud or abuse, and to protect
    people or property, including physical security programs;
    <br />
    • To de-identify the data or create aggregated datasets, such as for consolidating reporting, research or analytics;
    <br />
    • To make back-up copies for business continuity and disaster recovery purposes; and
    <br />
    • For corporate governance, including mergers, acquisitions and divestitures.</em
  >
</neo-modal>
