<neo-modal
  (closed)="closed.emit()"
  [titleSeparator]="true"
  [title]="'landing.footer.termsOfUseLabel' | translate"
  class="title-h2"
  size="large">
  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading"
      style="font-size: 14pt">
      Zeigo Network Terms of Use
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      1.<span style="mso-spacerun: yes">  </span>Acceptance of the Terms of Use
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      These terms of use are entered by and between the users accessing this site and the Zeigo Network member
      organization such users are employed by (collectively, “you”) and Summit Energy Services, Inc., and its affiliated
      companies (“Company”, “we” or “us”). The following terms and conditions, together with any documents they
      expressly incorporate by reference (collectively, these “Terms of Use”), govern your access to and use of
      network.zeigo.com, including any content, functionality and services offered on or through network.zeigo.com (the
      “Website”), whether as a guest or a registered user. Please read these Terms of Use carefully before you start to
      use the Website. By using the Website, you accept and agree to be bound and abide by these Terms of Use and our
      Privacy Policy, which is incorporated herein by reference and can be found at
      <a
        href="https://www.se.com/ww/en/about-us/legal/data-privacy.jsp"
        target="_blank"
        class="link underline">
        https://www.se.com/ww/en/about-us/legal/data-privacy.jsp
      </a>
      . If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Website.
    </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      By agreeing to these Terms of Use, you represent and warrant that you are authorized to
      <span class="GramE">enter into</span> legally binding contracts on behalf of your employer and that your employer
      agrees to be bound by these terms and conditions.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      2.<span style="mso-spacerun: yes">  </span>Changes to the Terms of Use
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective
      immediately when we post them and apply to all access to and use of the Website or Website Content (as that term
      is defined in “Intellectual Property Rights”) thereafter.
    </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      Your continued use of the Website or the Website Content following the posting of revised Terms of Use means that
      you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any
      changes, as they are binding on you.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      3.<span style="mso-spacerun: yes">  </span>Accessing the Website and Account Security
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      We reserve the right to withdraw or amend this Website, and any Website Content, in our sole discretion without
      notice. We may create certain limited access content and allow participation in a program to encourage
      coordination of renewable energy project sourcing and providing supplier education and engagement (“Program”) for
      which separate fees and separate terms and conditions may apply. We will not be liable if for any reason all or
      any part of the Website or Website Content is unavailable at any time or for any period. From time to time, in our
      sole discretion, we may restrict access to some parts or <span class="GramE">all of</span> the Website, or Website
      Content to users, including registered users participating in the Program.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB"> You are responsible for: </span>
  </p>

  <br />

  <ul style="list-style: inside; padding-left: 24px">
    <li class="MsoNormal">
      <span lang="EN-GB"> Making all arrangements necessary for you to have access to the Website; and </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Ensuring that all persons who access the Website through your internet connection are aware of these Terms of
        Use and comply with them.
      </span>
    </li>
  </ul>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      To access the Website or some of the Website Content, you may be asked to provide certain registration details or
      other information. To participate in the Program, you will be required to provide certain registration details and
      other information as determined by us.<span style="mso-spacerun: yes">  </span>It is a condition of your use of
      the Website that all the information you provide on the Website is correct, current and complete. You agree that
      all information you provide to register with this Website or otherwise, including but not limited to
      <span class="GramE">through the use of</span> any interactive features on the Website, is governed by our Privacy
      Policy, and you consent to all actions we take with respect to your information consistent with our Privacy
      Policy.
    </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      You will treat your <span class="GramE">user name</span>, password and any other piece of information provided as
      part of our security procedures as confidential and you must not disclose it to any other person or entity. You
      also acknowledge that your account is personal to you and agree not to provide any other person with access to
      this Website or portions of it using your <span class="GramE">user name</span>, password or other security
      information.
    </span>

    <span
      lang="EN-GB"
      style="font-family: 'Helvetica', sans-serif">
      You are solely responsible for maintaining the confidentiality and security of your account including username and
      password. Company is not responsible for any losses arising out of the unauthorized use of your account. You agree
      that Company does not have any responsibility if you lose or share access to your device.<span
        style="mso-spacerun: yes">
         
      </span>
    </span>

    <span lang="EN-GB">
      You agree to notify us immediately of any unauthorized access to your account or use of your
      <span class="GramE">user name</span> or password or any other breach of security. You also agree to ensure that
      you exit from your account at the end of each session. You should use reasonable caution when accessing your
      account from a public or shared computer so that others are not able to view or record your password or other
      personal information. We have the right to disable any <span class="GramE">user name</span>, password or other
      identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason,
      including if, in our opinion, you have violated any provision of these Terms of Use.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      4.<span style="mso-spacerun: yes">  </span>Intellectual Property Rights
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      The Website and its entire contents, features and functionality (including but not limited to all information,
      material, software, text, displays, images, video and audio, and the design, selection and arrangement thereof)
      (collectively “Website Content”), are owned by the Company, its licensors or other providers of such material and
      are protected by United States and international copyright, trademark, patent, trade secret and other intellectual
      property or proprietary rights laws.
    </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      These Terms of Use permit you to use the Website and the Website Content for your organization’s own use only. You
      must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish,
      download, store or transmit any of the Website Content, except as follows:
    </span>
  </p>

  <br />

  <ul style="list-style: inside; padding-left: 24px">
    <li class="MsoNormal">
      <span lang="EN-GB">
        Your computer may temporarily store copies of Website Content in RAM incidental to your accessing and viewing
        such Website Content.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        You may store Website Content that is automatically cached by your Web browser for display enhancement purposes.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        You may print one copy of a reasonable number of pages of the Website Content for your own personal,
        non-commercial use and not for further reproduction, publication or distribution.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        If we provide desktop, mobile or other applications for download, you may download a single copy to your
        computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by
        the applicable end user license agreement for such applications.
      </span>
    </li>
  </ul>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB"> You must not: </span>
  </p>

  <br />

  <ul style="list-style: inside; padding-left: 24px">
    <li class="MsoNormal">
      <span lang="EN-GB"> Modify copies of any Website <span class="GramE">Content;</span> </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Delete or alter any copyright, trademark or other proprietary rights notices from copies of Website Content; or
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB"> Resell or republish any part of the Website or any Website Content. </span>
    </li>
  </ul>

  <br />

  <p
    class="MsoNormal"
    style="margin-bottom: 0; text-align: justify">
    <span lang="EN-GB">
      If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the
      Website or Website Content in breach of the Terms of Use, your right to use the Website will cease immediately and
      you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest
      in or to the Website or any Website Content is transferred to you, and all rights not expressly granted are
      reserved by Company and its licensors. Any use of the Website or Website Content not expressly permitted by these
      Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.<b
        style="mso-bidi-font-weight: normal">
      </b>
    </span>
  </p>

  <p
    class="MsoNormal"
    style="margin-bottom: 0; text-align: justify">
    <span lang="EN-GB"> &nbsp; </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      The Company name, the terms “Schneider Electric” “Life is <span class="GramE">On</span>” “Zeigo Network”, (“Zeigo
      Network”) logo and all related names, logos, product and service names, designs and slogans (collectively “Company
      Marks”) are trademarks of the Company or its affiliates or licensors. You must not use the Company Marks without
      the prior written permission of the Company. All other names, logos, product and service names, designs and
      slogans on this Website are the trademarks of their respective owners and all goodwill arising from the use of
      such marks including, but not limited to, the Company Marks, will inure to the benefit of the respective owner.
    </span>
  </p>

  <br />

  <p
    class="MsoCommentText"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      5.<span style="mso-spacerun: yes">  </span>Confidentiality
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="margin-right: -14.2pt; text-align: justify">
    <span
      lang="EN-US"
      style="
        font-family: 'Arial', sans-serif;
        mso-fareast-font-family: 'Times New Roman';
        mso-ansi-language: EN-US;
        mso-fareast-language: FR;
      ">
      You acknowledge that certain information and materials made available within the password protected areas of the
      Website as part of the Program may be the confidential information of Company only to be used for your
      organization’s own purposes subject to the terms and conditions of your participation in the Program. You will not
      disclose such Confidential Information to any third parties or non-user employees during the term of your
      <span class="GramE">Website</span> access or at any time during the 5-year period following the end of such term
      unless authorized in writing by Company. You will take all reasonable measures to avoid disclosure, dissemination
      or unauthorized use of such Confidential Information, including, at a minimum, those measures you take to protect
      your own Confidential Information of a similar nature.
    </span>
  </p>

  <p
    class="MsoNormal"
    style="margin-right: -14.2pt; text-align: justify">
    <span
      lang="EN-US"
      style="
        font-family: 'Arial', sans-serif;
        mso-fareast-font-family: 'Times New Roman';
        mso-ansi-language: EN-US;
        mso-fareast-language: FR;
      "
      >You acknowledge that we may collect information regarding your business including electric usage, other
      information you provide to us and contract details <span class="GramE">during the course of</span> you using the
      Website and other interactions. We will not use or disclose such information to any third parties or non-user
      employees except as provided in the Privacy Policy and as provided in these Terms of Use. Notwithstanding anything
      to the contrary in these Terms of Use or the Privacy Policy, you agree that:
    </span>
  </p>

  <ul style="list-style: inside; padding-left: 24px">
    <li class="MsoNormal">
      <span
        lang="EN-US"
        style="
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          mso-ansi-language: EN-US;
          mso-fareast-language: FR;
        ">
        Company may release and use such data, and allow third parties authorized by Company to release and use such
        data, in an aggregated and nonidentifiable way; -
      </span>
    </li>

    <li class="MsoNormal">
      <span
        lang="EN-US"
        style="
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          mso-ansi-language: EN-US;
          mso-fareast-language: FR;
        ">
        Company may disclose and distribute the foregoing information and User Contributions (defined below) to its
        affiliates, licensors, service providers, consultants, contractors,
      </span>

      <span
        lang="EN-GB"
        style="font-family: 'Arial', sans-serif; mso-fareast-font-family: 'Times New Roman'; mso-fareast-language: FR">
        and alliance partners (collectively, “Service Providers”) to carry out data processing activities and to provide
        our products, services and online content to you. Service Providers include, without limitation, providers of
        hosting facilities, information systems, advertising and marketing agencies, IT support, security services,
        financial services, carriers who deliver products, outside accounting firms, lawyers and auditors; and
      </span>
    </li>

    <li class="MsoNormal">
      Company may collect your data from and through your use of the Schneider Electric Resource Advisor or if you
      provide your data to Company in another manner (for example, via a manual upload or by using the UtilityAPI) and
      Company may then share such data with Company’s third party suppliers, partners and providers in order to provide
      you with the services. Additionally, if you then request an introduction to one or more Zeigo Network solution
      provider(s), Company may share your data with the solution provider(s) you select for the purpose of helping you
      obtain any services you may request or require from such solution provider(s). 
    </li>
    <li class="MsoNormal">Corporate Users: Company may enable all or part of your private messages on the platform to be viewed by other
      platform users of the Solution Provider company you are messaging. </li>
    <li class="MsoNormal"> Solution Provider Users: Company may enable all
      or part of your private messages on the platform to be viewed by other platform members of your Solution Provider
      company.<br />
      <br />
      We ensure all Service Providers working under contract for Company are compliant with data privacy laws
      and aligned with Company guidelines.
     </li>
  </ul>

  <br />
  <p
    class="MsoCommentText"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      6.<span style="mso-spacerun: yes">  </span>Communications
    </span>
  </p>

  <br />
  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      By registering for the Zeigo Network you agree to receive email notifications from the Zeigo Network. We enable
      you to change your communication settings via the platform and Unsubscribe links on emails received.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      We will contact you via email, direct messages on our website, forums on our website, and website notifications.
      We will send you messages about activity on our website, upcoming digital and in-person events, and security or
      other service-related matters. You may change your email communication
      <a
        target="_blank"
        [routerLink]="['/settings']"
        href="#"
        class="link underline"
        >settings</a
      >
      at any time. Please note that you cannot opt-out of certain legal and security related communications.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      We also send a monthly newsletter to update you regarding platform content and opportunities. You can unsubscribe
      from the newsletter at any time via the Unsubscribe link at the bottom of the newsletter.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      If you opt-in to receive the <b>Welcome Series</b> during onboarding you will receive a series of 6 emails
      orienting you as to the content and functionality on the website. You can unsubscribe from the welcome series at
      any time via the Unsubscribe link at the bottom of each email.
    </span>
  </p>

  <br />
  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      We also enable communications between members, such as <b>Messages</b> and <b>Forum</b> posts. Notification of
      these communications can also be modified in
      <a
        target="_blank"
        href="#"
        [routerLink]="['/settings']"
        class="link underline"
        >settings</a
      >.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      7.<span style="mso-spacerun: yes">  </span>Your Use of the Website
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      You may use the Website and Website Content only for lawful purposes and in accordance with these Terms of Use.
      You agree not to use the Website or the Website Content:
    </span>
  </p>

  <ul style="list-style: inside; padding-left: 24px">
    <li class="MsoNormal">
      <span lang="EN-GB">
        In any way that violates any applicable federal, state, local or international law or regulation (including,
        without limitation, any laws regarding the export of data or software to and from the US or other countries).
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to
        inappropriate content, asking for personally identifiable information or otherwise.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        To send, knowingly receive, upload, download, use or re-use any material which does not comply with the Content
        Standards set out in these Terms of Use.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”,
        “chain letter” or “spam” or any other similar solicitation.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        To impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or
        entity (including, without limitation, by using e-mail addresses or screen names associated with any of the
        foregoing).
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB"> To harvest or collect information about users of the Website. </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB"> To cause injury to any person or entity. </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Website, or which, as
        determined by us, may harm the Company or users of the Website or expose them to liability.
      </span>
    </li>
  </ul>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB"> Additionally, you agree not to: </span>
  </p>

  <ul style="list-style: inside; padding-left: 24px">
    <li class="MsoNormal">
      <span lang="EN-GB">
        Use the Website in any manner that could disable, overburden, damage, or impair the Website or restrict, inhibit
        or otherwise interfere with any other person’s use of the Website or Website Content, including, but not limited
        to, their ability to engage in real time activities through the Website.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Use any robot, spider site search/retrieval application or other manual or automatic device, process or means to
        retrieve, index, “scrape,” “data mine” or otherwise access the Website for any purpose, including monitoring or
        copying any of the Website Content, or reproduce or circumvent the navigational structure or presentation of the
        Website.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Use any manual process to monitor or copy any of the Website Content or for any other unauthorized purpose
        without our prior written consent.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Use any device, software or routine that interferes with the proper working of the Website.
      </span>
    </li>

    <li class="MsoNormal">
      <span
        lang="EN-US"
        style="font-family: 'Arial', sans-serif; mso-fareast-font-family: 'Times New Roman'; mso-ansi-language: EN-US">
        Frame or mirror any portion of the Website, or otherwise incorporate any portion of the Website into any product
        or service.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Make available through or in connection with the Website any viruses, Trojan horses, worms, logic bombs or other
        material which is malicious or technologically harmful
      </span>

      <span
        lang="EN-US"
        style="font-family: 'Arial', sans-serif; mso-fareast-font-family: 'Times New Roman'; mso-ansi-language: EN-US">
        or invasive or intended to damage or hijack the operation of, or to monitor the use of, any hardware, software,
        or equipment.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Website, the server
        on which the Website is stored, or any server, computer or database connected to the Website.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.
      </span>
    </li>

    <li class="MsoNormal">
      <span
        lang="EN-US"
        style="font-family: 'Arial', sans-serif; mso-fareast-font-family: 'Times New Roman'; mso-ansi-language: EN-US">
        Interfere with or disrupt the operation of the Website or the systems, servers, or networks used to make the
        Website available, including by hacking or defacing any portion of the Website; or violate any requirement,
        procedure or policy of such servers or networks.
      </span>
    </li>

    <li class="MsoNormal">
      <span
        lang="EN-US"
        style="font-family: 'Arial', sans-serif; mso-fareast-font-family: 'Times New Roman'; mso-ansi-language: EN-US">
        Use the Website for any commercial purpose, or for any purpose that is fraudulent or otherwise tortious or
        unlawful.
      </span>
    </li>

    <li class="MsoNormal">
      <span
        lang="EN-US"
        style="font-family: 'Arial', sans-serif; mso-fareast-font-family: 'Times New Roman'; mso-ansi-language: EN-US">
        Reproduce, modify, adapt, download, translate, create derivative works of, sell, rent, lease, loan, timeshare,
        distribute, or otherwise exploit any portion of (or any use of) the Website or Website Content except as
        expressly authorized in these Terms of Use, without Company’s express prior written consent.
      </span>
    </li>

    <li class="MsoNormal">
      <span
        lang="EN-US"
        style="font-family: 'Arial', sans-serif; mso-fareast-font-family: 'Times New Roman'; mso-ansi-language: EN-US">
        Reverse engineer, decompile, or disassemble any portion of the Website, except where such restriction is
        expressly prohibited by applicable law.
      </span>
    </li>

    <li class="MsoNormal">
      <span
        lang="EN-US"
        style="font-family: 'Arial', sans-serif; mso-fareast-font-family: 'Times New Roman'; mso-ansi-language: EN-US">
        Remove any copyright, trademark, or other proprietary rights notice from the Website or Website Content.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB"> Otherwise attempt to interfere with the proper working of the Website. </span>
    </li>
  </ul>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      You will not attempt to do anything, or permit, encourage, assist, or allow any third party to do anything,
      prohibited in this section, or attempt, permit, encourage, assist, or allow any other violation of these Terms of
      Use.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      8.<span style="mso-spacerun: yes">  </span>User Contributions
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB"
      >The Website Content may include message boards, chat rooms, personal web pages or profiles, company web pages or
      profiles, project web pages or profiles, forums, bulletin boards, whitepapers, case studies, articles, videos,
      webinars, and other interactive features (collectively, “Interactive Services”) that allow users to post, submit,
      publish, display or transmit to Company, other users or other persons (hereinafter, “post”) content or materials
      (collectively, “User Contributions”) on or through the Website.
    </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      All User Contributions must comply with the Content Standards set out in these Terms of Use.
    </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      Notwithstanding anything to the contrary in any separate nondisclosure agreement or other contract between you and
      Company, any User Contribution you post to the site will be considered non-confidential and non-proprietary. By
      providing any User Contribution on the Website, you grant us and our affiliates, licensors, and service providers,
      and each of their and our respective licensees, successors and assigns a non-exclusive, worldwide, royalty-free,
      <span class="GramE">fully-paid</span>, perpetual and irrevocable license to use, reproduce, modify, perform,
      display, distribute and otherwise disclose to third parties any such material.<span style="mso-spacerun: yes">
         
      </span>
      You acknowledge that Company and our affiliates, licensors, and service providers, and each of their and our
      respective licensees, successors and assigns may use User Contributions as an input to aggregate representative
      market data or market benchmarks developed and published by Company and its affiliates where you are not
      individually identified and may distribute this information to its licensors in an anonymized aggregated format.
    </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      By using the Website, you hereby grant to Company and its affiliates a non-exclusive, royalty-free,
      <span class="GramE">fully-paid</span> license to use your company’s logo on the Website community page to indicate
      your participation in the Program. <span style="mso-spacerun: yes"> </span>
    </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB"
      >By using the Website, you hereby grant Company and its affiliates with permission to access your energy
      consumption information held within the Schneider Electric Resource Advisor system (RA). This data will be treated
      confidentially and not disclosed to third parties unless approved by you in writing or as specified in Section 17
      of this agreement.
    </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">Additionally, <span style="mso-spacerun: yes"> </span> </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB"> You represent and warrant that: </span>
  </p>

  <br />

  <ul style="list-style: inside; padding-left: 24px">
    <li class="MsoNormal">
      <span lang="EN-GB">
        You own or control all rights in and to the User Contributions and have the right to grant the license granted
        above to us and our affiliates, licensors and service providers, and each of their and our respective licensees,
        successors and assigns.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        All your User Contributions do and will comply with these Terms of Use including, but not limited to, the
        Content Standards.
      </span>
    </li>
  </ul>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and
      you, not the Company or its licensors, have full responsibility for such content, including its legality,
      reliability, accuracy and appropriateness.
    </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      We are not responsible, or liable to you or any third party, for the content or accuracy of any User Contributions
      posted by you or any other user of the Website.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      9.<span style="mso-spacerun: yes">  </span>Monitoring and Enforcement
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB"> We have the right (but not the obligation) to: </span>
  </p>

  <br />

  <ul style="list-style: inside; padding-left: 24px">
    <li class="MsoNormal">
      <span lang="EN-GB">
        Remove or refuse to post any User Contributions for any or no reason in our sole discretion.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole
        discretion, including if we believe that such User Contribution violates the Terms of Use, including the Content
        Standards, infringes any intellectual property right or other right of any person or entity, threatens the
        personal safety of users of the Website or the public or could create liability for the Company.
      </span>
    </li>

    <li class="MsoNormal">
      <span
        lang="EN-GB"
        style="font-family: 'Helvetica', sans-serif">
        Monitor, evaluate, and <span class="SpellE">analyze</span> User Contributions, and any use of and access to the
        Website, including to determine compliance with these Terms of Use and any other operating rules that may be
        established by Company from time to time.
      </span>
    </li>

    <li class="MsoNormal">
      <span
        lang="EN-GB"
        style="font-family: 'Helvetica', sans-serif">
        Edit, move, delete, or refuse to make available any User Contribution made available through, the Website, for
        any reason, including violation of these Terms of Use, whether for legal or other reasons.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Subject to and if permitted by applicable law, disclose your identity or other information about you to any
        third party who claims that material posted by you violates their rights, including their intellectual property
        rights or their right to privacy.
      </span>
    </li>
  </ul>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      We also have the right to take appropriate legal action, including without limitation, referral to law
      enforcement, for any illegal or unauthorized use of the Website.
    </span>
  </p>

  <p
    class="MsoNormal"
    style="margin-bottom: 0; text-align: justify">
    <span lang="EN-GB">
      Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court
      order requesting or directing us to disclose the identity or other information of anyone posting any materials on
      or through the Website.
    </span>
  </p>

  <p
    class="MsoNormal"
    style="margin-bottom: 0; text-align: justify">
    <span lang="EN-GB"><span style="mso-spacerun: yes"> </span> </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      YOU WAIVE AND HOLD HARMLESS COMPANY AND ITS AFFILIATES, LICENSORS, LICENSEES, AND SERVICE PROVIDERS FROM ANY
      CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS
      AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT
      AUTHORITIES.
    </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      However, we do not undertake to review all material before it is posted on the Website and cannot ensure prompt
      removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or
      inaction regarding transmissions, communications or content provided by any user or third party. We have no
      liability or responsibility to anyone for performance or non-performance of the activities described in this
      section.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      10.<span style="mso-spacerun: yes">  </span>Termination
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      style="font-family: 'Helvetica', sans-serif">
      These Terms of Use are effective unless and until terminated by either you or Company. You may terminate these
      Terms of Use at any time, <span class="GramE">provided that</span> you discontinue any further use of the Website
      and Website Content. We also may terminate these Terms of Use at any time and may do so immediately without
      notice, and deny you access to the Website and Website Content for any reason including if in our sole discretion
      you fail to comply with any term or provision of these Terms of Use. Upon any termination of these Terms of Use by
      either you or Company, you must promptly destroy all Website Content downloaded or otherwise obtained from the
      Website, as well as all copies of such Website Content, whether made under these Terms of Use or otherwise. The
      sections of these Terms of Use that by their nature are intended to survive termination shall survive any
      termination of these Terms of Use.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      11.<span style="mso-spacerun: yes">  </span>Content Standards
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      These content standards (“Content Standards”) apply to <span class="GramE">any and all</span> User Contributions
      and use of Interactive Services. User Contributions must in their entirety comply with all applicable federal,
      state, local and international laws and regulations. Without limiting the foregoing, User Contributions must not:
    </span>
  </p>

  <ul style="list-style: inside; padding-left: 24px">
    <li class="MsoNormal">
      <span lang="EN-GB">
        Contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful,
        inflammatory or otherwise objectionable.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion,
        nationality, disability, sexual orientation or age.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB"> Infringe or misappropriate any patent, trademark, trade secret, copyright </span>

      <span
        lang="EN-US"
        style="font-family: 'Arial', sans-serif; mso-fareast-font-family: 'Times New Roman'; mso-ansi-language: EN-US"
        >trade secret, right of publicity or privacy,
      </span>

      <span lang="EN-US"> </span>

      <span lang="EN-GB"> or other intellectual property or other rights of any other person. </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that
        could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may
        <span class="GramE">be in conflict with</span> these Terms of Use and our Privacy Policy.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB"> Be likely to deceive any person. </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB"> Promote any illegal activity, or advocate, promote or assist any unlawful act. </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other
        person. ·Impersonate any person or misrepresent your identity or affiliation with any person or organization.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB"> Involve contests, sweepstakes, general sales promotions, barter or advertising. </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not
        the case.
      </span>
    </li>

    <li class="MsoNormal">
      <span
        lang="EN-US"
        style="font-family: 'Arial', sans-serif; mso-fareast-font-family: 'Times New Roman'; mso-ansi-language: EN-US">
        Contain advertisements, solicitations, or spam links to other web sites or individuals, without prior written
        permission from Company.
      </span>
    </li>

    <li class="MsoNormal">
      <span
        lang="EN-US"
        style="font-family: 'Arial', sans-serif; mso-fareast-font-family: 'Times New Roman'; mso-ansi-language: EN-US">
        Be designed to cause or cause harm, damage, disable, or otherwise interfere with the Website or our licensors.
      </span>
    </li>
  </ul>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      12.<span style="mso-spacerun: yes">  </span>Copyright Infringement
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      We take claims of copyright infringement seriously. We will respond to notices of alleged copyright infringement
      that comply with applicable law. If you believe any materials accessible on or from this Website infringe your
      copyright, you may request removal of those materials (or access to them) from the Website by submitting written
      notification to our Copyright Agent (designated below). In accordance with the Online Copyright Infringement
      Liability Limitation Act of the Digital Millennium Copyright Act (17 U.S.C. § 512) (&quot;DMCA&quot;), the written
      notice (the &quot;DMCA Notice&quot;) must include substantially the following:
    </span>
  </p>

  <br />

  <ul style="list-style: inside; padding-left: 24px">
    <li class="MsoNormal">
      <span lang="EN-GB"> Your physical or electronic signature. </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Identification of the copyrighted work you believe to have been infringed or, if the claim involves multiple
        works on the Website, a representative list of such works.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Identification of the material you believe to be infringing in a sufficiently precise manner to allow us to
        locate that material.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Adequate information by which we can contact you (including your name, postal address, telephone number and, if
        available, e-mail address).
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        A statement that you have a good faith belief that use of the copyrighted material is not authorized by the
        copyright owner, its agent or the law.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB"> A statement that the information in the written notice is accurate. </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.
      </span>
    </li>
  </ul>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB"> Our designated Copyright Agent to receive DMCA Notices is: </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB"> Zeigo Network Administration 10350 Ormsby Park Pl, Suite 400, Louisville, KY 40223 </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB"> zeigonetwork@se.com </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      If you fail to comply with <span class="GramE">all of</span> the requirements of Section 512(c)(3) of the DMCA,
      your MCA Notice may not be effective.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      Please be aware that if you knowingly materially misrepresent that material or activity on the Website is
      infringing your copyright, you may be held liable for damages (including costs and attorneys' fees) under Section
      512(f) of the DMCA.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      It is our policy in appropriate circumstances to disable and/or terminate the accounts of users who are repeat
      infringers.
    </span>
  </p>

  <br />
  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      13.<span style="mso-spacerun: yes">  </span>Reliance on Information Posted
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      The Website Content is made available solely for general information purposes. Neither us nor our licensors
      warrant the accuracy, completeness or usefulness of this information. Any reliance you place on such information
      is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on
      such materials by you or any other visitor to the Website, or by anyone who may be informed of any of its
      contents.
    </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      This Website Content may include content provided or owned by third parties, including materials provided by other
      users, bloggers and third-party licensors, syndicators, aggregators and/or reporting services. All statements
      and/or opinions expressed in these materials, and all articles and responses to questions and other content, other
      than the Website Content provided by the Company, are solely the opinions and the responsibility of the person or
      entity providing those materials. These materials do not necessarily reflect the opinion of the Company. We are
      not responsible, or liable to you or any third party, for the content or accuracy of any Website Content provided
      by any third parties.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      14.<span style="mso-spacerun: yes">  </span>Changes to the Website Content
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      We may update the Website Content from time to time, but the Website Content is not necessarily complete,
      error-free or <span class="GramE">up-to-date</span>. Any of the Website Content may be out of date at any given
      time, and we are under no obligation to update such Website Content.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      15.<span style="mso-spacerun: yes">  </span>Information About You and Your Visits to the Website
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      All information we collect on this Website is subject to our Privacy Policy. By using the Website or Website
      Content, you consent to all actions taken by us with respect to your information in compliance with the Privacy
      Policy.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      16.<span style="mso-spacerun: yes">  </span>Linking to the Website and Social Media Features
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our
      reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of
      association, approval or endorsement on our part without our prior express written consent.
    </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB"> This Website may provide certain social media features that enable you to: </span>
  </p>

  <br />

  <ul style="list-style: inside; padding-left: 24px">
    <li class="MsoNormal">
      <span lang="EN-GB"> Link from your own or certain third-party websites to certain Website Content. </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Send e-mails or other communications with certain Website Content, or links to certain Website Content.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Cause limited portions of Website Content to be displayed or appear to be displayed on your own or certain
        third-party websites.
      </span>
    </li>
  </ul>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      You may use these features solely as they are provided by us and otherwise in accordance with any additional terms
      and conditions we provide with respect to such features. Subject to the foregoing, you must not:
    </span>
  </p>

  <br />

  <ul style="list-style: inside; padding-left: 24px">
    <li class="MsoNormal">
      <span lang="EN-GB"> Establish a link from any website that is not owned by you. </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB">
        Cause the Website Content or portions of it to be displayed, or appear to be displayed by, for example, framing,
        deep linking or in-line linking, on any other site.
      </span>
    </li>

    <li class="MsoNormal">
      <span lang="EN-GB"> Link to any part of the Website other than the homepage. </span>
    </li>
  </ul>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      Otherwise take any action with respect to the materials on this Website that is inconsistent with any other
      provision of these Terms of Use. You agree to cooperate with us in causing any unauthorized framing or linking
      immediately to cease. We reserve the right to withdraw linking permission without notice. We may disable all or
      any social media features and any links at any time without notice in our discretion.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      17.<span style="mso-spacerun: yes">  </span>Third Party Sites
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      References on the Website to any names, marks, products or services of third parties, or links to third party
      sites and resources are provided for your convenience only and are not an endorsement, sponsorship, or
      recommendation of the third party or its information, products, or services. This includes links contained in
      advertisements, including banner advertisements and sponsored links. Company and its affiliates have no control
      over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that
      may arise from your use of them nor any responsibility for the acts or omissions of any operator of any such site
      or platform. If you decide to access any such third-party websites or platforms, you do so entirely at your own
      risk and subject to the third party’s terms and policies (including its privacy policy).
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      18.<span style="mso-spacerun: yes">  </span>Third Party Licensor Applications and Functionality
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      The Website and Website Content may give you access to products, applications, software, services, materials
      and/or functionality (collectively, “Third Party Applications”) that is owned and/or licensed by Company’s
      third-party licensors (collectively, “Company’s Licensor(s)”). The end user license agreements provided by or made
      available by Company’s Licensors shall govern your use of and rights and obligations with respect to Third Party
      Applications. In the absence of a separate end user license agreement provided by or made available by Company’s
      Licensors these Terms of Use shall govern your use of <span class="GramE">Third Party</span> Applications.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      19.<span style="mso-spacerun: yes">  </span>The Website and Website Content
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span class="GramE">
      <b style="mso-bidi-font-weight: normal">
        <span lang="EN-GB"> 19.1 <span style="mso-spacerun: yes"> </span>Disclosure </span>
      </b>
    </span>

    <b style="mso-bidi-font-weight: normal">
      <span lang="EN-GB"> </span>
    </b>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      For purposes of Program access being provided to you, you acknowledge that Schneider Electric may be compensated
      by solution providers who are
      <span class="GramE">also <span style="mso-spacerun: yes"> </span>Program</span> members. If this compensation is
      tied to a transaction we introduce to you, you will be notified at the time of the introduction.
    </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span class="GramE">
      <b style="mso-bidi-font-weight: normal">
        <span lang="EN-GB"> 19.2<span style="mso-spacerun: yes">  </span>Disclaimer </span>
      </b>
    </span>

    <b style="mso-bidi-font-weight: normal">
      <span lang="EN-GB"> of Warranties <span style="mso-spacerun: yes"> </span> </span>
    </b>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      You understand that we cannot and do not guarantee or warrant that the Website will be free of viruses, error free
      or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy
      your <span class="GramE">particular requirements</span> for anti-virus protection and accuracy of data input and
      output, and for maintaining a means external to our site for any reconstruction of any lost data. NEITHER COMPANY
      NOR ITS LICENSORS (INCLUDING THEIR RESPECTIVE AFFILIATES) WILL BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
      DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
      COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY
      SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY
      WEBSITE LINKED TO IT.
    </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      YOUR USE OF THE WEBSITE, THE WEBSITE CONTENT, THE PROGRAM, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE
      IS AT YOUR OWN RISK. THE WEBSITE, THE WEBSITE CONTENT, THE PROGRAM, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
      WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR
      IMPLIED. NEITHER THE COMPANY, ITS LICENSORS (INCLUDING THEIR RESPECTIVE AFFILIATES) NOR ANY PERSON ASSOCIATED WITH
      COMPANY OR ITS LICENSORS MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
      RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE, THE PROGRAM, OR THE WEBSITE CONTENT. WITHOUT
      LIMITING THE FOREGOING, NEITHER THE COMPANY, ITS LICENSORS (INCLUDING THEIR RESPECTIVE AFFILIATES) NOR ANYONE
      ASSOCIATED WITH THE COMPANY OR ITS LICENSORS REPRESENTS OR WARRANTS THAT THE WEBSITE, THE WEBSITE CONTENT, THE
      PROGRAM, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR
      UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
      VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE, WEBSITE CONTENT, THE PROGRAM, OR ANY SERVICES OR ITEMS
      OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
    </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      THE COMPANY AND ITS LICENSORS (INCLUDING THEIR RESPECTIVE AFFILIATES) HEREBY DISCLAIM ALL WARRANTIES OF ANY KIND,
      WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
      MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      20.<span style="mso-spacerun: yes">  </span>Limitation on Liability
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      TO THE FULLEST EXTENT PROVIDED BY APPLICABLE LAW, IN NO EVENT WILL COMPANY, ITS LICENSORS, OR THEIR RESPECTIVE
      AFFILIATES, LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY
      KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY
      WEBSITES LINKED TO IT, ANY WEBSITE CONTENT OR CONTENT ON SUCH OTHER WEBSITES, THE PROGRAM, OR ANY SERVICES OR
      ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
      CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL
      DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
      GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN
      IF FORESEEABLE AND/OR EVEN IF ANY REMEDY HEREIN FAILS OF ITS ESSENTIAL PURPOSE.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      21.<span style="mso-spacerun: yes">  </span>Limitation on Time to File Claims
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE, THE WEBSITE CONTENT,
      OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF
      ACTION OR CLAIM IS PERMANENTLY BARRED.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      22.<span style="mso-spacerun: yes">  </span>Indemnification
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and
      its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and
      assigns from and against any and all claims, liabilities, damages, judgments, awards, losses, costs, expenses or
      fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or
      your use of the Website, including, but not limited to, your User Contributions, the Program, any use of the
      Website Content or services and products obtained from the Website other than as expressly authorized in these
      Terms of Use, or your use of any information obtained from the Website.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      23.<span style="mso-spacerun: yes">  </span>Governing Law and Jurisdiction
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      All matters relating to the Website and these Terms of <span class="GramE">Use</span> and any dispute or claim
      arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be
      governed by and construed in accordance with the internal laws of the State of Delaware without giving effect to
      any choice or conflict of law provision or rule (whether of the State of Delaware or any other jurisdiction).
    </span>
  </p>

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the Website shall be
      instituted exclusively in the federal courts of the United States or the courts of the State of Delaware, although
      we retain the right to bring any suit, action or proceeding against you for breach of these Terms of Use in your
      country of residence or any other relevant country. You waive <span class="GramE">any and all</span> objections to
      the exercise of jurisdiction over you by such courts and to venue in such courts.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      24.<span style="mso-spacerun: yes">  </span>Arbitration
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB">
      At Company's sole discretion, it may require You to submit any disputes arising from the use of these Terms of Use
      or the Website, including disputes arising from or concerning their interpretation, violation, invalidity,
      non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the American
      Arbitration Association applying Delaware law.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      25.<span style="mso-spacerun: yes">  </span>General
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB"
      >The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and the Company with
      respect to the Website and supersede all prior and contemporaneous understandings, agreements, representations and
      warranties, both written and oral, with respect to the Website.<span style="mso-spacerun: yes">  </span>No waiver
      of by the Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing
      waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to
      assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision. If
      any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid,
      illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such
      that the remaining provisions of the Terms of Use will continue in full force and effect.<span
        style="mso-spacerun: yes"
        > 
      </span>
    </span>

    <span
      lang="EN-GB"
      style="font-family: 'Helvetica', sans-serif"
      >These Terms of Use do not, and shall not be construed to, create any partnership, joint venture,
      employer-employee, agency, or franchisor-franchisee relationship between you and Company. Headings used in these
      Terms of Use are for reference purposes only and in no way define or limit the scope of the section.
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span
      lang="EN-GB"
      class="span-heading">
      26.<span style="mso-spacerun: yes">  </span>Your Comments and Concerns
    </span>
  </p>

  <br />

  <p
    class="MsoNormal"
    style="text-align: justify">
    <span lang="EN-GB"
      >This website is operated by Summit Energy Services, Inc., located at 10350 Ormsby Park Pl, Suite 400, Louisville,
      KY 40223. All other feedback, comments, requests for technical support and other communications relating to the
      Website should be directed to: zeigonetwork@se.com.
    </span>
  </p>
</neo-modal>
