<div class="d-flex justify-between align-center pl-32 pr-32">
  <div class="d-flex align-center">
    <div class="circle circle-small circle-violet-5 mr-16"></div>
    <div class="line breadcrumb-line"></div>
  </div>
  <div class="d-flex">
    <div class="circle circle-big circle-violet-5 mr-16"></div>
    <div class="circle circle-big circle-violet-5 mr-16"></div>
    <div class="circle circle-big circle-violet-5 mr-20"></div>
    <div class="circle circle-big circle-violet-5 mr-10"></div>
    <div class="flex-column justify-center">
      <div class="line username-line mb-12"></div>
      <div class="line company-line"></div>
    </div>
  </div>
</div>