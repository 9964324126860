// modules
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';

// components
import { ModalComponent } from './components/modal/modal.component';
import { ContactNeoNetworkComponent } from './components/contact-neo-network/contact-neo-network.component';
import { FormsModule } from '@angular/forms';
import { AttachToInitiativeComponent } from './components/attach-to-initiative/attach-to-initiative.component';

@NgModule({
  declarations: [ModalComponent, ContactNeoNetworkComponent, AttachToInitiativeComponent],
  imports: [SharedModule, FormsModule],
  exports: [ModalComponent, ContactNeoNetworkComponent, AttachToInitiativeComponent]
})
export class ModalModule {}
