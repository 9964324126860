<div class="d-flex justify-between align-center pl-32 pr-32">
  <div class="d-flex align-center">
    <div class="circle circle-small circle-violet-5 mr-16"></div>
    <div class="line breadcrumb-line"></div>
  </div>
  <div class="d-flex">
    <div class="rectangle rectangle-small rectangle-light-gray mr-16"></div>
    <div class="vertical-line mr-16"></div>
    <div class="rectangle rectangle-big rectangle-light-gray mr-16"></div>
    <div class="rectangle rectangle-big rectangle-light-gray mr-20"></div>
  </div>
</div>