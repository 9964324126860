const redo = {
  data:
    '<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M13 6.83334C12.6 6.83334 12.3333 7.1 12.3333 7.5C12.3333 9.43334 11.3333 11.1667 9.66668 12.1C7.13334 13.5667 3.86668 12.7 2.40001 10.1667C0.933343 7.63334 1.80001 4.36667 4.33334 2.9C6.53334 1.63334 9.20001 2.1 10.8667 3.83334H9.26668C8.86668 3.83334 8.60001 4.1 8.60001 4.5C8.60001 4.9 8.86668 5.16667 9.26668 5.16667H12.2667C12.6667 5.16667 12.9333 4.9 12.9333 4.5V1.5C12.9333 1.1 12.6667 0.833336 12.2667 0.833336C11.8667 0.833336 11.6 1.1 11.6 1.5V2.7C10.3333 1.5 8.73334 0.833336 7.00001 0.833336C3.33334 0.833336 0.333344 3.83334 0.333344 7.5C0.333344 11.1667 3.33334 14.1667 7.00001 14.1667C10.6667 14.1667 13.6667 11.1667 13.6667 7.5C13.6667 7.1 13.4 6.83334 13 6.83334Z" fill="currentColor"/>' +
    '</svg>',
  name: 'redo'
};

//Zeigo Rebrand : Community Landing Page Add Circle
const addCircle = {
  data:
    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M10 7V13M13 10H7M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#6F6E77" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>',
  name: 'add-circle'
};

const uploadImage = {
  data: `<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.87491 5.96909L5.87039 5.97361L5.86934 5.97257C5.8692 5.97049 5.86909 5.96784 5.86909 5.96457C5.86909 5.9613 5.8692 5.95865 5.86934 5.95658L9.99217 1.83375C9.99424 1.83361 9.99689 1.8335 10.0002 1.8335C10.0034 1.8335 10.0061 1.83361 10.0082 1.83375L14.131 5.95658C14.1311 5.95865 14.1312 5.9613 14.1312 5.96457C14.1312 5.96502 14.1312 5.96545 14.1312 5.96587C14.1308 5.96587 14.1304 5.96587 14.1299 5.96587C14.1267 5.96587 14.124 5.96576 14.1219 5.96562L10.855 2.69869L10.0015 1.84514V3.05225V14.2002C10.0015 14.2082 10.0008 14.2132 10.0003 14.2158C10.0003 14.2161 10.0002 14.2165 10.0002 14.2167C10.0001 14.2165 10 14.2161 9.99998 14.2158C9.99951 14.2132 9.99886 14.2082 9.99886 14.2002V3.05225V1.84514L9.14531 2.69869L5.87491 5.96909ZM1.35997 18.6404V19.1404H1.85997H18.1404H18.6404V18.6404V13.0544C18.6404 13.0511 18.6405 13.0489 18.6407 13.0475C18.6418 13.0459 18.6443 13.0427 18.6491 13.038C18.6508 13.0362 18.6523 13.0348 18.6536 13.0336C18.655 13.0349 18.6565 13.0364 18.6581 13.038C18.6629 13.0427 18.6654 13.0459 18.6665 13.0475C18.6667 13.0489 18.6668 13.0511 18.6668 13.0544V19.1488C18.6647 19.1513 18.6618 19.1544 18.6581 19.1581C18.6544 19.1618 18.6513 19.1647 18.6488 19.1668H1.35156C1.34902 19.1647 1.3459 19.1618 1.34221 19.1581C1.33852 19.1544 1.33567 19.1513 1.3335 19.1488V13.0544C1.3335 13.0511 1.33367 13.0489 1.33382 13.0475C1.33494 13.0459 1.33747 13.0427 1.34221 13.038C1.34381 13.0364 1.3453 13.0349 1.34668 13.0336C1.348 13.0348 1.34953 13.0362 1.35125 13.038C1.356 13.0427 1.35853 13.0459 1.35965 13.0475C1.3598 13.0489 1.35997 13.0511 1.35997 13.0544V18.6404Z" fill="currentColor" stroke="currentColor"/>
  </svg>
  `,
  name: 'upload-image'
};

export const solidIcons = [redo, addCircle, uploadImage];
