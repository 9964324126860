const starIcon = {
  data:
    '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M8.61047 2.62406C8.75458 2.27758 9.24542 2.27758 9.38953 2.62406L10.984 6.45765C11.0448 6.60372 11.1821 6.70352 11.3398 6.71616L15.4785 7.04796C15.8526 7.07795 16.0042 7.54476 15.7192 7.78888L12.566 10.49C12.4459 10.5929 12.3934 10.7544 12.4301 10.9083L13.3935 14.9469C13.4805 15.3119 13.0834 15.6004 12.7632 15.4048L9.2199 13.2406C9.0849 13.1581 8.9151 13.1581 8.7801 13.2406L5.2368 15.4048C4.91656 15.6004 4.51947 15.3119 4.60654 14.9469L5.5699 10.9083C5.60661 10.7544 5.55414 10.5929 5.434 10.49L2.28075 7.78888C1.99576 7.54476 2.14744 7.07795 2.52149 7.04796L6.66018 6.71616C6.81787 6.70352 6.95524 6.60372 7.01599 6.45765L8.61047 2.62406Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>',
  name: 'star'
};
const filterStarIcon = {
  data:
    '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M5.703 1.14233C5.8128 0.878339 6.18676 0.878339 6.29656 1.14233L7.5114 4.06316C7.55769 4.17445 7.66235 4.25049 7.7825 4.26012L10.9358 4.51292C11.2208 4.53577 11.3363 4.89143 11.1192 5.07743L8.71673 7.1354C8.62519 7.21381 8.58522 7.33685 8.61318 7.45409L9.34718 10.5312C9.41352 10.8093 9.11097 11.0291 8.86698 10.88L6.16732 9.23111C6.06446 9.16829 5.9351 9.16829 5.83223 9.23111L3.13258 10.88C2.88859 11.0291 2.58604 10.8093 2.65238 10.5312L3.38637 7.45409C3.41434 7.33685 3.37436 7.21381 3.28282 7.1354L0.880354 5.07743C0.663217 4.89143 0.778779 4.53577 1.06378 4.51292L4.21706 4.26012C4.33721 4.25049 4.44187 4.17445 4.48815 4.06316L5.703 1.14233Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>',
  name: 'filter-star'
};

const starOutlineIcon = {
  data:
    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M9.48061 1.4987C9.67276 1.03673 10.3272 1.03673 10.5193 1.4987L12.6453 6.61016C12.7263 6.80492 12.9095 6.93799 13.1197 6.95485L18.638 7.39724C19.1367 7.43722 19.339 8.05963 18.959 8.38514L14.7546 11.9866C14.5945 12.1238 14.5245 12.3391 14.5734 12.5443L15.8579 17.9292C15.974 18.4159 15.4446 18.8005 15.0176 18.5397L10.2932 15.6541C10.1132 15.5441 9.88678 15.5441 9.70677 15.6541L4.98238 18.5397C4.55539 18.8005 4.02594 18.4159 4.14203 17.9292L5.42652 12.5443C5.47546 12.3391 5.4055 12.1238 5.24531 11.9866L1.04099 8.38514C0.660997 8.05964 0.86323 7.43722 1.36197 7.39724L6.88022 6.95485C7.09048 6.93799 7.27363 6.80492 7.35464 6.61016L9.48061 1.4987Z" stroke="#6F6E77" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>',
  name: 'star-outline'
};

const notificationIcon = {
  data:
    ' <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M11.1426 12.8113C12.5636 12.6427 13.9337 12.3087 15.2333 11.8289C14.1557 10.6328 13.4999 9.04923 13.4999 7.3125V6.78689C13.5 6.7746 13.5 6.76231 13.5 6.75C13.5 4.26472 11.4853 2.25 9 2.25C6.51472 2.25 4.5 4.26472 4.5 6.75L4.49985 7.3125C4.49985 9.04923 3.84396 10.6328 2.76636 11.8289C4.06605 12.3087 5.43632 12.6428 6.85735 12.8113M11.1426 12.8113C10.44 12.8946 9.72493 12.9375 8.99985 12.9375C8.27488 12.9375 7.55991 12.8946 6.85735 12.8113M11.1426 12.8113C11.2124 13.0283 11.25 13.2598 11.25 13.5C11.25 14.7426 10.2426 15.75 9 15.75C7.75736 15.75 6.75 14.7426 6.75 13.5C6.75 13.2598 6.78764 13.0284 6.85735 12.8113" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>',
  name: 'notification'
};

const noNotificationIcon = {
  data:
    '<svg width="31" height="33" viewBox="0 0 31 33" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M20.1424 24.7578C23.2211 24.3926 26.1898 23.6688 29.0056 22.6293C26.6708 20.0376 25.2497 16.6067 25.2497 12.8438V11.7049C25.2499 11.6783 25.25 11.6517 25.25 11.625C25.25 6.24022 20.8848 1.875 15.5 1.875C10.1152 1.875 5.75 6.24022 5.75 11.625L5.74968 12.8438C5.74968 16.6067 4.32857 20.0376 1.99377 22.6293C4.80978 23.6689 7.7787 24.3927 10.8576 24.7579M20.1424 24.7578C18.62 24.9384 17.0707 25.0312 15.4997 25.0312C13.9289 25.0312 12.3798 24.9384 10.8576 24.7579M20.1424 24.7578C20.2934 25.2281 20.375 25.7295 20.375 26.25C20.375 28.9424 18.1924 31.125 15.5 31.125C12.8076 31.125 10.625 28.9424 10.625 26.25C10.625 25.7295 10.7066 25.2281 10.8576 24.7579M13.0625 10.4062H17.9375L13.0625 17.7188H17.9375" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>',
  name: 'no-notification'
};

const searchIcon = {
  data:
    '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.75 2.125C4.6434 2.125 2.125 4.6434 2.125 7.75C2.125 10.8566 4.6434 13.375 7.75 13.375C9.30347 13.375 10.7089 12.7461 11.7275 11.7275C12.7461 10.7089 13.375 9.30347 13.375 7.75C13.375 4.6434 10.8566 2.125 7.75 2.125ZM0.875 7.75C0.875 3.95304 3.95304 0.875 7.75 0.875C11.547 0.875 14.625 3.95304 14.625 7.75C14.625 9.42319 14.0266 10.9575 13.0333 12.1494L16.9419 16.0581C17.186 16.3021 17.186 16.6979 16.9419 16.9419C16.6979 17.186 16.3021 17.186 16.0581 16.9419L12.1494 13.0333C10.9575 14.0266 9.42319 14.625 7.75 14.625C3.95304 14.625 0.875 11.547 0.875 7.75Z" fill="currentColor"/>' +
    '</svg>',
  name: 'search'
};

const calendarIcon = {
  data:
    '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M5.625 1.875C5.97018 1.875 6.25 2.15482 6.25 2.5V3.75H13.75V2.5C13.75 2.15482 14.0298 1.875 14.375 1.875C14.7202 1.875 15 2.15482 15 2.5V3.75H15.625C17.0057 3.75 18.125 4.86929 18.125 6.25V15.625C18.125 17.0057 17.0057 18.125 15.625 18.125H4.375C2.99429 18.125 1.875 17.0057 1.875 15.625V6.25C1.875 4.86929 2.99429 3.75 4.375 3.75H5V2.5C5 2.15482 5.27982 1.875 5.625 1.875ZM16.875 9.375C16.875 8.68464 16.3154 8.125 15.625 8.125H4.375C3.68464 8.125 3.125 8.68464 3.125 9.375V15.625C3.125 16.3154 3.68464 16.875 4.375 16.875H15.625C16.3154 16.875 16.875 16.3154 16.875 15.625V9.375Z" fill="#FDFCFF"/>' +
    '</svg>',
  name: 'calendar'
};

export const headerIcons = [
  filterStarIcon,
  notificationIcon,
  noNotificationIcon,
  searchIcon,
  starIcon,
  starOutlineIcon,
  calendarIcon
];
