<p class="two-lines-ellipsis text-s pl-12 text-neo-near-black">
  <a
    *ngIf="notification?.details?.userName"
    class="text-neo-near-black fw-700 c-pointer"
    [routerLink]="
      notification?.details?.userStatusId === userStatuses.Deleted
        ? []
        : ['/user-profile/', notification?.details?.userId]
    ">
    {{ notification?.details?.userName }}
  </a>

  {{
    (notification?.details?.userName
      ? 'notificationType.companyIFollowAddEmployeeLabel'
      : 'notificationType.multipleCompanyIFollowAddEmployeeLabel'
    ) | translate
  }}

  <a
    *ngIf="notification?.details?.userStatusId !== userStatuses.Deleted"
    class="text-neo-near-black fw-700 c-pointer"
    routerLink="/company-profile/{{ notification?.details?.companyId }}">
    {{ notification?.details?.companyName }}</a
  >.
</p>
