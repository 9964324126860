<div class="selected-item menu-item d-flex align-center">
  <div class="circle mr-16"></div><div class="line"></div>
</div>
<div class="menu-item d-flex align-center">
  <div class="circle mr-16"></div><div class="line"></div>
</div>
<div class="menu-item d-flex align-center">
  <div class="circle mr-16"></div><div class="line"></div>
</div>
<div class="menu-item d-flex align-center">
  <div class="circle mr-16"></div><div class="line"></div>
</div>
<div class="menu-item d-flex align-center">
  <div class="circle mr-16"></div><div class="line"></div>
</div>
<div class="menu-item d-flex align-center">
  <div class="circle mr-16"></div><div class="line"></div>
</div>
<div class="menu-item d-flex align-center">
  <div class="circle mr-16"></div><div class="line"></div>
</div>
<div class="menu-item d-flex align-center">
  <div class="circle mr-16"></div><div class="line"></div>
</div>
<div class="menu-item d-flex align-center mt-30-percent" *ngIf="!isPublicUser">
  <div class="circle mr-16"></div><div class="line"></div>
</div>
<div class="menu-item d-flex align-center" *ngIf="!isPublicUser">
  <div class="circle mr-16"></div><div class="line"></div>
</div>