export enum OnboardingStepsEnum {
  Start = 'start',
  Role = 'role',
  Responsibilities = 'responsibilities',
  Location = 'location',
  PersonalInfo = 'personalInfo',
  GeographicalPurview = 'geographicalPurview',
  Interests = 'interests',
  Finish = 'finish',
  DecarbonizationSolutions= 'decarbonizationSolutions'
}



export const YESNODATA = [
  { id: 1, name: 'Yes'},
  {id: 2, name: 'No' }
];