const fingerUp = {
  data:
    '<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M5.63257 9.25C6.43892 9.25 7.16648 8.80416 7.6641 8.16967C8.43726 7.18384 9.41169 6.3634 10.5255 5.77021C11.2477 5.38563 11.8743 4.81428 12.1781 4.05464C12.3908 3.5231 12.5 2.95587 12.5 2.38338V1.75C12.5 1.33579 12.8358 1 13.25 1C14.4926 1 15.5 2.00736 15.5 3.25C15.5 4.40163 15.2404 5.49263 14.7766 6.46771C14.511 7.02604 14.8836 7.75 15.5019 7.75M15.5019 7.75H18.6277C19.6544 7.75 20.5733 8.44399 20.682 9.46486C20.7269 9.88708 20.75 10.3158 20.75 10.75C20.75 13.5976 19.7581 16.2136 18.101 18.2712C17.7134 18.7525 17.1142 19 16.4962 19H12.4802C11.9966 19 11.5161 18.922 11.0572 18.7691L7.94278 17.7309C7.48393 17.578 7.00342 17.5 6.51975 17.5H4.90421M15.5019 7.75H13.25M4.90421 17.5C4.98702 17.7046 5.07713 17.9054 5.17423 18.1022C5.37137 18.5017 5.0962 19 4.65067 19H3.74289C2.85418 19 2.02991 18.482 1.77056 17.632C1.43208 16.5226 1.25 15.3451 1.25 14.125C1.25 12.5725 1.54481 11.0889 2.08149 9.72711C2.38655 8.95303 3.16733 8.5 3.99936 8.5H5.05212C5.52404 8.5 5.7973 9.05591 5.5523 9.45925C4.72588 10.8198 4.25 12.4168 4.25 14.125C4.25 15.3185 4.48232 16.4578 4.90421 17.5Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> ' +
    '</svg>',
  name: 'finger-up'
};

const fingerUpFilled = {
  data:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M21.3 10.08C21.019 9.74269 20.6674 9.47113 20.27 9.28452C19.8726 9.0979 19.439 9.0008 19 9.00004H14.44L15 7.57004C15.2329 6.94393 15.3105 6.27065 15.2261 5.60797C15.1416 4.94528 14.8977 4.31297 14.5152 3.76527C14.1327 3.21758 13.623 2.77084 13.0299 2.46339C12.4369 2.15593 11.778 1.99694 11.11 2.00004C10.9176 2.00045 10.7295 2.05632 10.5681 2.16097C10.4067 2.26561 10.2789 2.41459 10.2 2.59004L7.35 9.00004H7V22H5V9.00004C4.20435 9.00004 3.44129 9.31611 2.87868 9.87872C2.31607 10.4413 2 11.2044 2 12V19C2 19.7957 2.31607 20.5588 2.87868 21.1214C3.44129 21.684 4.20435 22 5 22H17.73C18.4318 21.9998 19.1113 21.7535 19.6503 21.3041C20.1893 20.8546 20.5537 20.2304 20.68 19.54L21.95 12.54C22.0286 12.1074 22.011 11.6628 21.8987 11.2377C21.7864 10.8126 21.582 10.4174 21.3 10.08Z" fill="currentColor"/>' +
    '</svg>',
  name: 'finger-up-filled'
};

const handThumpsUpFilled = {
  data:
    '<svg width="22" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M7.49281 18.5C7.06823 18.5 6.67296 18.2635 6.51759 17.8684C6.18349 17.0187 6 16.0933 6 15.125C6 13.3759 6.59874 11.7667 7.60244 10.491C7.75335 10.2993 7.97456 10.1821 8.20214 10.094C8.67496 9.91091 9.09254 9.57968 9.4141 9.16967C10.1873 8.18384 11.1617 7.3634 12.2755 6.77021C12.9977 6.38563 13.6243 5.81428 13.9281 5.05464C14.1408 4.5231 14.25 3.95587 14.25 3.38338V2.75C14.25 2.33579 14.5858 2 15 2C16.2426 2 17.25 3.00736 17.25 4.25C17.25 5.40163 16.9904 6.49263 16.5266 7.46771C16.261 8.02604 16.6336 8.75 17.2519 8.75H20.3777C21.4044 8.75 22.3233 9.44399 22.432 10.4649C22.4769 10.8871 22.5 11.3158 22.5 11.75C22.5 14.5976 21.5081 17.2136 19.851 19.2712C19.4634 19.7525 18.8642 20 18.2462 20H14.2302C13.7466 20 13.2661 19.922 12.8072 19.7691L9.69278 18.7309C9.23393 18.578 8.75342 18.5 8.26975 18.5H7.49281Z" fill="currentColor"/>' +
    '<path d="M2.33149 10.7271C1.79481 12.0889 1.5 13.5725 1.5 15.125C1.5 16.3451 1.68208 17.5226 2.02056 18.632C2.27991 19.482 3.10418 20 3.99289 20H4.90067C5.3462 20 5.62137 19.5017 5.42423 19.1022C4.83248 17.9029 4.5 16.5528 4.5 15.125C4.5 13.4168 4.97588 11.8198 5.8023 10.4593C6.0473 10.0559 5.77404 9.5 5.30212 9.5H4.24936C3.41733 9.5 2.63655 9.95303 2.33149 10.7271Z" fill="currentColor"/>' +
    '</svg>',
  name: 'hands-thumps-up-filled'
};

const rss = {
  data:
    '<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M10.625 16.75V16.125C10.625 12.6732 7.82678 9.875 4.375 9.875H3.75M3.75 4.25H4.375C10.9334 4.25 16.25 9.56662 16.25 16.125V16.75M5 16.125C5 16.4702 4.72018 16.75 4.375 16.75C4.02982 16.75 3.75 16.4702 3.75 16.125C3.75 15.7798 4.02982 15.5 4.375 15.5C4.72018 15.5 5 15.7798 5 16.125Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>',
  name: 'rss'
};

export const forumIcons = [fingerUp, fingerUpFilled, handThumpsUpFilled, rss];
